import React from 'react';
import { View, Text, ScrollView } from 'react-native';
import HeaderBar from './HeaderBar';

const { PRIMARY_COLOR } = require('./Data');

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: 'busy'
    };
  }

  async componentDidMount() {
  }

  componentWillMount() {
  }

  submit() {

  }

  back() {
    this.props.history.goBack();
  }

  render() {


    return (
      <View style={{ flex: 1, backgroundColor: PRIMARY_COLOR }}>
        <HeaderBar type='green' title={'Syarat & Ketentuan'} goBack={() => this.back()} />
        <View style={{ flex: 1 }}>
          <ScrollView style={{ flex: 1, paddingHorizontal: 32, paddingVertical: 32 }}>
            <Text style={{ color: 'white' }}>
              <p>
                GREENS merupakan perusahaan agritech berbasis di Indonesia. Website dan/atau aplikasi GREENS merupakan
                sebuah platform bertujuan untuk memudahkan Pengguna dalam mendapatkan informasi dan pelayanan secara digital.
              </p>
              <p>
                BACALAH SYARAT DAN KETENTUAN ini secara cermat dan teliti, GREENS adalah sebuah brand agritech
                selanjutnya disebut “GREENS”. Dengan menggunakan, mengunduh dan memasang aplikasi perangkat lunak
                termasuk tetapi tidak terbatas menggunakan informasi dan jasa Layanan yang tersedia didalam platform GREENS,
                maka Pengguna dianggap telah memahami dan menyetujui isi dari Syarat Dan Ketentuan sebagaimana yang
                ditetapkan oleh GREENS. Dengan menyetujui Syarat Dan Ketentuan penggunaan informasi dan jasa Layanan ini,
                Pengguna juga menyetujui ketentuan penggunaan tambahan, kebijakan Privasi, Disclaimer termasuk ketentuan
                penggunaan pada setiap informasi dan jasa Layanan (apabila ada), dan perubahannya yang merupakan bagian yang
                tidak terpisahkan dari ketentuan penggunaan ini (selanjutnya, Ketentuan Penggunaan, Ketentuan Penggunaan
                tambahan, dan perubahannya secara bersama-sama disebut sebagai “Ketentuan Penggunaan”). Meskipun
                merupakan satu kesatuan, Ketentuan Penggunaan tambahan akan berlaku dalam hal terdapat perbedaan dengan
                Ketentuan Penggunaan. Dari waktu ke waktu apabila diperlukan GREENS berhak untuk melakukan perubahan dan
                penyesuian Syarat Dan Ketentuan, Ketentuan Penggunaan yang berlaku, oleh karena itu GREENS menyarankan
                kepada Pengguna untuk senantiasa secara berkala membaca dan memahami Syarat Dan Ketentuan ini agar tidak
                terjadi kesalahpahaman dalam penafsiran peraturan dan ketentuan yang berlaku.
              </p>
              <p>
                Apabila Pengguna tidak menyetujui isi dari Syarat Dan Ketentuan ini, maka Pengguna dapat menghapus dan/atau
                tidak menggunakan aplikasi perangkat lunak termasuk tidak mengakses penyediaan informasi dan jasa Layanan yang
                tersedia didalam platform GREENS.
              </p>
              <p>
                SYARAT DAN KETENTUAN INI MERUPAKAN DOKUMEN YANG DIANGGAP SEBAGAI SUATU PERJANJIAN
                YANG SAH SECARA HUKUM DAN MENGIKAT PENGGUNA DENGAN GREENS BESERTA REKANAN
                DAN/ATAU AFFILIASI GREENS YANG TERGABUNG DALAM PENYEDIAAN INFORMASI DAN JASA LAYANAN
                GREENS.
              </p>
              <h3 style={{ color: 'white' }}>A. Definisi</h3>
              <p>
                Istilah-istilah dengan menggunakan huruf besar, sebagaimana disebutkan dibawah ini, memiliki makna
                sebagaimana diberikan pada istilah-istilah tersebut dibawah ini, kecuali bila konteksnya secara jelas
                menyatakan lain:
                <ol>
                  <li>“Data” berarti setiap data, informasi dan/atau keterangan dalam bentuk apapun yang didapatkan dari waktu
                    ke waktu yang Pengguna ataupun Pihak Ketiga lain yang bersangkutan (input/upload) saat menggunakan
                    platform GREENS.</li>
                  <li>“Platform” adalah aplikasi web dan/atau mobile base, dimana penyediaan atas Layanan hanya dapat
                    diakses melalui Situs Web dan/atau Mobile.</li>
                  <li>“Help Desk” adalah operator yang dipersiapkan oleh GREENS untuk menindaklanjuti setiap pengaduan
                    dan keluhan dari Pengguna ataupun Pihak Ketiga lain yang bersangkutan terkait permasalahan
                    penggunaan Platform yang tersedia melalui alamat email support@greens.id.</li>
                  <li>“Kode Akses” berarti kode yang diberikan oleh GREENS kepada Pengguna ataupun Pihak Ketiga lain yang
                    bersangkutan untuk mengakses informasi dan jasa Layanan yang tersedia didalam platform, Kode Akses
                    berupa “OTP (One Time Password)”.</li>
                  <li>“Pengguna” adalah individu yang cakap hukum atau badan hukum lainnya yang didirikan berdasarkan
                    peraturan dan ketentuan hukum yang berlaku di Indonesia.6. “Situs Web” adalah situs internet milik dan dikelola oleh GREENS yakni www.greens.id dan domain-domain
                    lain yang terkait dalam system GREENS.</li>
                  <li>“Layanan” adalah sebagaimana penjelasan yang tercantum dalam ketentuan Syarat Dan Ketentuan ini.</li>
                </ol>
              </p>
              <h3 style={{ color: 'white' }}>B. Ketentuan Umum</h3>
              <p>
                <ol>
                  <li>GREENS merupakan platform digital yang menghubungkan Pengguna dengan GREENS dimana Pengguna
                    setuju untuk menggunakan informasi dan jasa Layanan yang disediakan dengan proses pendaftaran dan
                    pembayaran secara digital.</li>
                  <li>Dengan memiliki akun yang terdaftar didalam Platform, Pengguna dapat mengakses dan memilih jasa
                    Layanan secara terperinci sesuai dengan keinginan dan kebutuhan, GREENS hanya memfasilitasi
                    Pengguna untuk menemukan informasi dan jasa Layanan yang disediakan oleh GREENS.</li>
                  <li>Jasa Layanan sebagaimana yang telah dipilih oleh Pengguna akan terkoneksi dengan GREENS, dan akan
                    dikonfirmasi melalui email ataupun media komunikasi lain yang Pengguna daftarkan setelah proses
                    pendaftaran dan/atau pembayaran selesai dilaksanakan.</li>
                  <li>Sangat memungkinkan apabila pada saat penggunaan jasa Layanan yang telah terkonfirmasi, Pengguna
                    menghadapi kenyataan berbedanya jadwal dan/atau pembayaran dari jasa Layanan, GREENS
                    menyarankan agar Pengguna tetap melakukan koordinasi secara langsung dengan GREENS sebelum
                    waktu pelaksanaan jasa Layanan yang akan digunakan.</li>
                  <li>Dengan mengetahui bahwa GREENS merupakan perusahaan yang bergerak dibidang teknologi, yang
                    GREENS lakukan adalah menyediakan platform untuk memfasilitasi informasi, proses pendaftaran dan
                    pembayaran secara digital dalam penggunaan jasa Layanan yang disediakan dan olehkarenanya Pengguna
                    memiliki kebebasan penuh untuk tidak memilih menggunakan platform GREENS termasuk berhenti
                    menggunakan jasa Layanan yang ada.</li>
                  <li>Data-data yang dimasukkan ke dalam system layanan GREENS adalah merupakan tanggung jawab penuh
                    dari pemilik data yakni dari masing-masing Pengguna sesuai pada kapasitas tanggung-jawab masing-
                    masing sebagai pengguna mengenai keutuhan, keabsahan dan penggunaan data yang diberikan dan
                    GREENS hanya merupakan fasilitator system.</li>
                </ol>
              </p>
              <h3 style={{ color: 'white' }}>C. PENDAFTARAN DAN AKSESBILITAS PLATFORM</h3>
              <p>
                <ol>
                  <li>Bahwa sebelum menggunakan informasi dan jasa Layanan yang tersedia didalam platform GREENS,
                    Pengguna wajib melakukan pendaftaran atas Kode Akses, dimana pendaftaran tersebut membutuhkan
                    informasi atas Data sesuai dengan persayatan dan ketentuan yang berlaku.
                  </li><li>Pengguna menyatakan bahwa pemberian informasi atas Data yang tercantum didalam Platform GREENS
                    merupakan informasi yang sebenar-benarnya dan kebenaran valid yang bisa dibuktikan dengan dokumen
                    legalitas yang ada (apabila diperlukan).
                  </li><li>Pengguna dipersayaratkan adalah subjek hukum yang sah, dimana apabila pengguna adalah indvidu maka
                    merupakan individu yang cakap hukum dan apabila pengguna merupakan Badan Hukum harus memiliki
                    perizinan yang sesuai dengan peraturan dan ketentuan yang berlaku.
                  </li><li>Kode Akses yang diberikan merupakan hal yang bersifat sangat rahasia sehingga, Pengguna dilarang
                    untuk mengalihkan, menginformasikan dan atau memberitahukan kepada Pihak Ketiga lainnya yang bukan
                    merupakan Pihak Yang Ditunjuk oleh Pengguna, GREENS maupun affliasinya tidak pernah meminta Kode Akses Pengguna, dan penggunaan atas Kode Akses tersebut akan dianggap sebagai satu hal yang bersifat
                    subjektif yakni hanya menerangkan digunakan oleh Pengguna untuk mengakses Platform yang disediakan,
                    dan bersama dengan aksesbilitas tersebut maka segala kewajiban yang timbul termasuk konsekuensi
                    hukum yang melekat didalamnya merupakan tanggung jawab sepenuhnya Pihak Pengguna.
                  </li><li>Apabila Pengguna mengetahui aktivitas atas Kode Akses yang Pengguna miliki namun bukan dilakukan
                    oleh Pengguna, maka secepatnya Pengguna melaporkan aktivitas tersebut ke GREENS sesuai dengan
                    korespondensi yang tercantum dalam Syarat Dan Ketentuan ini.
                  </li><li>Kode Akses diperlukan oleh Pengguna untuk menggunakan informasi dan jasa Layanan yang terdapat
                    didalam Platform sesuai persyaratan dan ketentuan yang berlaku.
                  </li><li>Pengguna memahami dan mematuhi bahwa aksesbilitas atas Platform yang disediakan oleh GREENS
                    hanya dikhususkan untuk menggunakan informasi dan jasa Layanan yang tersedia, aktivitas lain yang
                    dilakukan termasuk menyebarkan virus, spam, sebagai back door pengambilan informasi Data, atau
                    tindakan lain yang sejenis yang dapat mengakibatkan kerusakan baik secara langsung maupun tidak
                    langsung terhadap Platform, termasuk tetapi tidak terbatas tindakan yang dapat menyebabkan kerugian
                    baik di Pihak GREENS maupun Pihak pengguna lainnya, tindakan lainnya berdasarkan peraturan dan
                    ketentuan hukum yang berlaku maka Pengguna bersedia diproses secara lebih lanjut sesuai dengan
                    hukum ditetapkan oleh Pemerintah Republik Indonesia.
                  </li><li>Pada saat Pengguna mengakses Platform yang disediakan oleh GREENS, sangat memungkinkan
                    Pengguna menemukan informasi dan/atau artikel mengenai teknologi, agrikultur, nutrisi dan/atau informasi
                    lainnya, dan Pengguna memahami bahwa informasi dan/atau artikel tersebut hanya merupakan konten
                    atau informasi umum, bukan merupakan saran atau opini yang dijadikan sebagai rujukan, olehkarenanya
                    Pengguna tetap harus berkonsultasi dengan tenaga ahli terkait yang ditunjuk.
                  </li>
                </ol>
              </p>
            </Text>
            <Text> </Text>
          </ScrollView>
        </View>
      </View>
    );

  }
}

export default Page;
