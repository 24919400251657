import React from 'react';
import feathersLib from '../feathersLib';
import Lib from '../Lib';
import { TextInput, View, Text } from 'react-native';
import Button from './Button';
import HeaderBar from './HeaderBar';

const { PRIMARY_COLOR, TEXT_GREY_COLOR } = require('./Data');

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: 'busy'
    };
  }

  async componentDidMount() {
    const phoneNum = this.props.match.params.data;
    this.setState({ phoneNum });
  }

  componentWillMount() {
  }

  async next() {
    const phoneNum = this.state.phoneNum;
    const otp = this.state.otp;
    try {
      const result = await feathersLib.verifyPhoneOTP(phoneNum, otp);
      console.log(result);
      const token = result.token;
      await Lib.setStorageValue('GREENS_TOKEN', token);
      this.props.history.push('/home');
    } catch (err) {
      console.error(err);
    }
  }

  back() {
    this.props.history.goBack();
  }

  render() {
    const inputStyle = { height: 40, padding: 5, outlineWidth: 0 };
    const borderWidth = 2;
    const enable = (this.state.otp && this.state.otp.length === 6);

    return (
      <View style={{ flex: 1 }}>
        <HeaderBar goBack={() => this.back()} />
        <View style={{ flex: 1, padding: 32 }}>
          <Text style={{ color: TEXT_GREY_COLOR, fontSize: 20, fontWeight: 700 }}>Masukan 6 digit kode OTP</Text>
          <Text> </Text>
          <View style={{ borderWidth: borderWidth, borderRadius: 8, borderColor: PRIMARY_COLOR }}>
            <View style={{ padding: 5 }}>
              <TextInput
                disabled={true}
                style={inputStyle}
                onChangeText={txt => this.setState({ phoneNum: txt })}
                value={this.state.phoneNum}
                placeholderTextColor={PRIMARY_COLOR}
                keyboardType='phone-pad'
                placeholder="No. Handphone" />
            </View>
            <View style={{ height: borderWidth, backgroundColor: PRIMARY_COLOR }} />
            <View style={{ padding: 5 }}>
              <TextInput
                maxLength={6}
                style={inputStyle}
                onChangeText={txt => { if (txt.length <= 6) this.setState({ otp: txt }); }}
                value={this.state.otp}
                placeholderTextColor={PRIMARY_COLOR}
                keyboardType='numeric'
                placeholder="Kode OTP" />
            </View>
          </View>
        </View>
        <View style={{ paddingVertical: 32, paddingHorizontal: 64 }}>
          <Button disabled={!enable} title='Lanjutkan' onPress={() => this.next()} />
        </View>
      </View>
    );

  }
}

export default Page;
