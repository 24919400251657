
module.exports = {
  PRIMARY_COLOR: '#5BA545',
  DARK_COLOR: '#046938',
  TEXT_GREY_COLOR: '#999999',
  LIGHT_GREEN_COLOR: '#E1FFD8',
  ORANGE_COLOR: '#FFBB55',
  LIGHT_ORANGE_COLOR: '#FFF3E1',
  ITEMS: { '5': 450000, '20': 1720000 } // '5': 450000, '20': 1720000
};
