import React from 'react';
import feathersLib from '../feathersLib';
// import Lib from '../Lib';
import Swal from 'sweetalert2';
import { TextInput, ActivityIndicator, View, Text, ScrollView } from 'react-native';
import Button from './Button';
import HeaderBar from './HeaderBar';
// import { message } from 'antd';

const { PRIMARY_COLOR } = require('./Data');

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: 'form'
    };
  }

  async componentDidMount() {
    await feathersLib.autoLogin();
  }

  componentWillMount() {
  }

  async submit() {
    try {
      const name = this.state.name;
      const email = this.state.email;
      const phoneNum = this.state.phoneNum;
      const title = this.state.title;
      const message = this.state.message;

      this.setState({ show: 'busy' });

      await feathersLib.createMessage({
        name,
        email,
        phoneNum,
        title,
        message
      });

      this.setState({ show: 'form' });
      Swal.fire({
        icon: 'success',
        html: 'Pesan terkirim'
      });
    } catch (err) {
      console.error(err);
      Swal.fire({
        icon: 'error',
        html: 'Pesan gagal terkirim'
      });
      this.setState({ show: 'form' });
    }
  }


  back() {
    this.props.history.goBack();
  }

  renderForm() {
    const borderColor = 'white';
    const inputStyle = { height: 40, padding: 5, outlineWidth: 0, color: borderColor };
    const inputStyle2 = { height: 80, padding: 5, outlineWidth: 0, color: borderColor };
    const borderWidth = 2;

    return (
      <View style={{ flex: 1, padding: 32 }}>
        <Text style={{ color: borderColor, fontSize: 14, fontWeight: 400, textAlign: 'center' }}>Butuh Bantuan? Ada pertanyaan? Ada Masalah? Kami ada disini untuk membantu.</Text>
        <Text> </Text>
        <View style={{ borderWidth: borderWidth, borderRadius: 8, borderColor: borderColor, paddingBottom: 16 }}>
          <View style={{ padding: 5 }}>
            <TextInput
              style={inputStyle}
              onChangeText={txt => this.setState({ name: txt })}
              value={this.state.name}
              placeholderTextColor={borderColor}
              placeholder="Nama Lengkap" />
          </View>
          <View style={{ height: borderWidth, backgroundColor: borderColor }} />
          <View style={{ padding: 5 }}>
            <TextInput
              style={inputStyle}
              onChangeText={txt => this.setState({ email: txt })}
              value={this.state.email}
              placeholderTextColor={borderColor}
              keyboardType='email-address'
              placeholder="Alamat Email" />
          </View>
          <View style={{ height: borderWidth, backgroundColor: borderColor }} />
          <View style={{ padding: 5 }}>
            <TextInput
              style={inputStyle}
              onChangeText={txt => this.setState({ phoneNum: txt })}
              value={this.state.phoneNum}
              placeholderTextColor={borderColor}
              keyboardType='phone-pad'
              placeholder="No. Handphone" />
          </View>
          <View style={{ height: borderWidth, backgroundColor: borderColor }} />
          <View style={{ padding: 5 }}>
            <TextInput
              style={inputStyle}
              onChangeText={txt => this.setState({ title: txt })}
              value={this.state.title}
              placeholderTextColor={borderColor}
              placeholder="Judul" />
          </View>
          <View style={{ height: borderWidth, backgroundColor: borderColor }} />
          <View style={{ padding: 5 }}>
            <TextInput
              style={inputStyle2}
              onChangeText={txt => this.setState({ message: txt })}
              value={this.state.message}
              multiline={true}
              placeholderTextColor={borderColor}
              placeholder="Pesan" />
          </View>
        </View>
      </View>
    );

  }


  render() {
    const name = this.state.name;
    const email = this.state.email;
    const phoneNum = this.state.phoneNum;
    const title = this.state.title;
    const message = this.state.message;

    const enable = (name && name.length > 0 &&
      email && email.length > 0 &&
      phoneNum && phoneNum.length > 0 &&
      title && title.length > 0
      && message && message.length > 0);

    let btn;
    if (this.state.show === 'busy') btn = <View style={{ paddingVertical: 32, alignItems: 'center', justifyContent: 'center' }}><ActivityIndicator /></View>;
    else btn = (
      <View style={{ paddingVertical: 32, paddingHorizontal: 64 }}>
        <Button disabled={!enable} type='outline' title='Kirim' onPress={() => this.submit()} />
      </View>
    );

    return (
      <View style={{ flex: 1, backgroundColor: PRIMARY_COLOR }}>
        <HeaderBar type='green' title='Contact Us' goBack={() => this.back()} />
        <View style={{ flex: 1 }}>
          <ScrollView style={{ flex: 1 }}>
            {this.renderForm()}
          </ScrollView>
        </View>
        {btn}
      </View>
    );

  }
}

export default Page;
