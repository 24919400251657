import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';
import { toExcel } from 'to-excel';
import { Dimensions } from 'react-native';

async function showToast(msg) {
  Toastify({
    backgroundColor: 'black',
    gravity: 'bottom', // `top` or `bottom`
    position: 'center', // `left`, `center` or `right`
    text: msg
  }).showToast();
}

async function delay(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, ms);
  })
}

// async function getLogger() {
//   return console;
// }

async function getStorageValue(key) {
  const val = window.localStorage.getItem(key);
  return val;
}

async function setStorageValue(key, val) {
  window.localStorage.setItem(key, val);
}

async function clearStorageValue() {
  window.localStorage.clear();
}

function openUrl(url) {
  const win = window.open(url, '_blank');
  win.focus();
}

function redirectToUrl(url) {
  window.location = url;
}

function remainingText(seconds) {
  let secs = seconds;
  let numDays = Math.floor(secs / (3600 * 24));
  secs = secs - (numDays * 3600 * 24);
  let numHours = Math.floor(secs / 3600);
  secs = secs - (numHours * 3600);
  let numMinute = Math.floor(secs / 60);
  secs = secs - (numMinute * 60);
  let numSeconds = secs;
  let ret = '';
  if (numDays > 0) ret += (numDays + ' days ');
  if (numHours > 0) ret += (numHours + ' hours ');
  if (numMinute > 0) ret += (numMinute + ' minutes ');
  if (numSeconds > 0) ret += (numSeconds + ' seconds');
  return ret;
}

function downloadCSV(headers, data, fn) {
  // const jsonArray = new JsonArray(arr);
  // const str = jsonArray.convertToCSVstring();
  // download("my.csv", str);
  toExcel.exportXLS(headers, data, fn);
}

function getScreenSize() {
  const ww = Dimensions.get('window').width;
  const wh = Dimensions.get('window').height;
  const landscape = (ww > wh);
  let sw = ww;
  let sh = wh;
  if (landscape) {
    sh = 0.9 * wh;
    sw = 360 * sh / 646;
  }

  return {
    width: sw,
    height: sh
  }
}

function idrAmount(amount) {
  let val = Math.floor(Math.abs(amount)).toLocaleString('id-ID');
  val = val.replace(/,/g, '.');
  return 'Rp ' + val;
}

export default {
  delay,
  openUrl,
  redirectToUrl,
  showToast,
  getStorageValue,
  setStorageValue,
  clearStorageValue,
  remainingText,
  downloadCSV,
  getScreenSize,
  idrAmount
};

