import React from 'react';
import { View, Dimensions } from 'react-native';
import {
  Switch,
  Route
} from "react-router-dom";

import Home from './Home';
import LoginStep1 from './LoginStep1';
import LoginStep2 from './LoginStep2';
import Cart from './Cart';
import MyProfile from './MyProfile';
import NewOrderStep1 from './NewOrderStep1';
import NewOrderStep2 from './NewOrderStep2';
import NewOrderStep3 from './NewOrderStep3';
import OrderHistory from './OrderHistory';
import ContactUs from './ContactUs';
import RefundPolicy from './RefundPolicy';
import TermAndCondition from './TermAndCondition';

class AppRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: 'busy'
    };
  }

  async componentDidMount() {
    const ww = Dimensions.get('window').width;
    const wh = Dimensions.get('window').height;
    const landscape = (ww > wh);
    let sw = ww;
    let sh = wh;
    if (landscape) {
      sh = 0.9 * wh;
      sw = 360 * sh / 646;
    }


    this.setState({ ww, wh, sw, sh, landscape, show: 'content' });

  }

  componentWillMount() {
  }

  render() {
    if (this.state.show === 'busy') return null;

    const landscape = this.state.landscape;
    const ww = this.state.ww;
    const wh = this.state.wh;
    const sw = this.state.sw;
    const sh = this.state.sh;

    const content = (
      <View style={{ width: sw, height: sh, backgroundColor: 'white' }}>
        <Switch>
          <Route exact path='/new-order-step-3' component={NewOrderStep3} />
          <Route exact path='/new-order-step-2/:data' component={NewOrderStep2} />
          <Route exact path='/new-order-step-1' component={NewOrderStep1} />
          <Route exact path='/cart' component={Cart} />
          <Route exact path='/login-step-1' component={LoginStep1} />
          <Route exact path='/login-step-2/:data' component={LoginStep2} />
          <Route exact path='/order-history' component={OrderHistory} />
          <Route exact path='/contact-us' component={ContactUs} />
          <Route exact path='/refund-policy' component={RefundPolicy} />
          <Route exact path='/term-and-condition' component={TermAndCondition} />
          <Route exact path='/my-profile' component={MyProfile} />
          <Route path='/' component={Home} />
        </Switch>
      </View>
    );

    if (landscape) {
      return (
        <View style={{ width: ww, height: wh, backgroundColor: 'gainsboro', alignItems: 'center', justifyContent: 'center' }}>
          {content}
        </View>
      );
    }

    return content;

  }
}

export default AppRouter;
