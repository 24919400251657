import React from 'react';
import moment from 'moment';
import { TextInput, Picker, View, Text, ScrollView } from 'react-native';
import Button from './Button';
import HeaderBar from './HeaderBar';

const { PRIMARY_COLOR } = require('./Data');

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: 'busy'
    };
  }

  async componentDidMount() {
    const dataStr = this.props.match.params.data;
    const data = JSON.parse(dataStr);
    this.setState({
      name: data.name,
      phoneNum: data.phoneNum,
      address: data.address
    });
    console.log(data);
  }

  componentWillMount() {
  }

  next(nextData) {
    this.props.history.push('/new-order-step-3/' + nextData);
  }

  back() {
    this.props.history.goBack();
  }

  render() {
    const inputStyle = { height: 40, padding: 5, outlineWidth: 0, color: PRIMARY_COLOR };
    const inputStyle2 = { height: 80, padding: 5, outlineWidth: 0, color: PRIMARY_COLOR };
    const borderWidth = 2;

    const formatDate = 'DD MMMM YYYY';

    let delta = 0;
    if (this.state.duration === '20') delta = 21;

    const val1 = moment().day(1 + 7).startOf('day').format();
    const val2 = moment().day(1 + 7 + 7).startOf('day').format();
    const val3 = moment().day(1 + 7 + 7 + 7).startOf('day').format();
    const label1 = moment().day(1 + 7).format(formatDate) + ' - ' + moment().day(5 + 7 + delta).format(formatDate);
    const label2 = moment().day(1 + 7 + 7).format(formatDate) + ' - ' + moment().day(5 + 7 + 7 + delta).format(formatDate);
    const label3 = moment().day(1 + 7 + 7 + 7).format(formatDate) + ' - ' + moment().day(5 + 7 + 7 + 7 + delta).format(formatDate);

    const periods = [{ val: '', label: 'Pilih Periode' }];
    periods.push({ val: val1, label: label1 });
    periods.push({ val: val2, label: label2 });
    periods.push({ val: val3, label: label3 });

    const duration = this.state.duration;
    const startDate = this.state.startDate;
    const pax = this.state.pax;
    const notes = this.state.notes;
    const name = this.state.name;
    const phoneNum = this.state.phoneNum;
    const address = this.state.address;

    const enableNext = (duration && startDate && pax && Number(duration) > 0 && startDate.length > 0 && Number(pax) > 0);
    let nextData;
    if (enableNext) {
      nextData = JSON.stringify({
        name,
        phoneNum,
        address,
        duration: Number(duration),
        startDate,
        pax: Number(pax),
        notes: (notes && notes.length > 0) ? notes : ''
      });
    }

    return (
      <View style={{ flex: 1 }}>
        <HeaderBar title='Pesanan Baru' goBack={() => this.back()} />
        <View style={{ flex: 1 }}>
          <ScrollView style={{ flex: 1, padding: 32 }}>
            <Text style={{ color: PRIMARY_COLOR, fontSize: 20, fontWeight: 700 }}>Katering</Text>
            <Text> </Text>
            <View style={{ borderWidth: borderWidth, borderRadius: 8, borderColor: PRIMARY_COLOR, paddingBottom: 16 }}>
              <View style={{ padding: 5 }}>
                <Picker
                  style={{ height: 40, padding: 0, borderWidth: 0, backgroundColor: 'white', color: PRIMARY_COLOR }}
                  selectedValue={this.state.duration}
                  onValueChange={(itemValue, itemIndex) =>
                    this.setState({ duration: itemValue })
                  }>
                  <Picker.Item label="Durasi Katering" />
                  <Picker.Item label="5 Hari" value="5" />
                  <Picker.Item label="20 Hari" value="20" />
                </Picker>
              </View>
              <View style={{ height: borderWidth, backgroundColor: PRIMARY_COLOR }} />
              <View style={{ padding: 5 }}>
                <Picker
                  style={{ height: 40, padding: 0, borderWidth: 0, backgroundColor: 'white', color: PRIMARY_COLOR }}
                  selectedValue={this.state.startDate}
                  onValueChange={(itemValue, itemIndex) =>
                    this.setState({ startDate: itemValue })
                  }>
                  {periods.map((item, index) => <Picker.Item key={index} label={item.label} value={item.val} />)}
                </Picker>
              </View>
              <View style={{ height: borderWidth, backgroundColor: PRIMARY_COLOR }} />
              <View style={{ padding: 5 }}>
                <TextInput
                  style={inputStyle}
                  onChangeText={txt => this.setState({ pax: txt })}
                  value={this.state.pax}
                  placeholderTextColor={PRIMARY_COLOR}
                  keyboardType='numeric'
                  placeholder="Jumlah Paket" />
              </View>
              <View style={{ height: borderWidth, backgroundColor: PRIMARY_COLOR }} />
              <View style={{ padding: 5 }}>
                <TextInput
                  style={inputStyle2}
                  onChangeText={txt => this.setState({ notes: txt })}
                  value={this.state.notes}
                  multiline={true}
                  placeholderTextColor={PRIMARY_COLOR}
                  placeholder="Catatan (alergi, dll)" />
              </View>
            </View>
          </ScrollView>
        </View >
        <View style={{ paddingVertical: 32, paddingHorizontal: 64 }}>
          <Button disabled={!enableNext} title='Lanjutkan' onPress={() => this.next(nextData)} />
        </View>
      </View >
    );

  }
}

export default Page;
