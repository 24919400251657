import React from 'react';
import feathersLib from '../feathersLib';
// import Lib from '../Lib';
import cartLib from '../cartLib';
import { TextInput, View, Text, ScrollView } from 'react-native';
import Button from './Button';
import HeaderBar from './HeaderBar';

const { PRIMARY_COLOR } = require('./Data');

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: 'busy'
    };
  }

  async componentDidMount() {
    const o = await cartLib.loadOrderData();
    this.setState({
      name: o.name,
      phoneNum: o.phoneNum,
      address: o.address,
      notes: o.notes
    });

    try {
      await feathersLib.autoLogin();
      console.log(feathersLib.user);
      const phoneNum = feathersLib.phoneNum;
      if (phoneNum) this.setState({ phoneNum, fixedPhoneNum: true });
    } catch (err) {
      console.error(err);
    }
  }

  componentWillMount() {
  }

  async next() {
    const name = this.state.name;
    const phoneNum = this.state.phoneNum;
    const address = this.state.address;
    const notes = this.state.notes;
    const data = {
      name,
      phoneNum,
      address,
      notes
    }
    await cartLib.saveOrderData(data);
    this.props.history.push('/new-order-step-3');
  }

  back() {
    this.props.history.goBack();
  }

  render() {
    const inputStyle = { height: 40, padding: 5, outlineWidth: 0 };
    const inputStyle2 = { height: 80, padding: 5, outlineWidth: 0 };
    const borderWidth = 2;

    const name = this.state.name;
    const phoneNum = this.state.phoneNum;
    const address = this.state.address;
    // const notes = this.state.notes;

    const enableNext = (name && phoneNum && address && name.length > 0 && phoneNum.length > 0 && address.length > 0);
    return (
      <View style={{ flex: 1 }}>
        <HeaderBar title='Pesanan Baru' goBack={() => this.back()} />
        <View style={{ flex: 1 }}>
          <ScrollView style={{ flex: 1, padding: 32 }}>
            <Text style={{ color: PRIMARY_COLOR, fontSize: 20, fontWeight: 700 }}>Data Pemesan</Text>
            <Text> </Text>
            <View style={{ borderWidth: borderWidth, borderRadius: 8, borderColor: PRIMARY_COLOR, paddingBottom: 16 }}>
              <View style={{ padding: 5 }}>
                <TextInput
                  style={inputStyle}
                  onChangeText={txt => this.setState({ name: txt })}
                  value={this.state.name}
                  placeholderTextColor={PRIMARY_COLOR}
                  placeholder="Nama Pemesan" />
              </View>
              <View style={{ height: borderWidth, backgroundColor: PRIMARY_COLOR }} />
              <View style={{ padding: 5 }}>
                <TextInput
                  disabled={(this.state.fixedPhoneNum)}
                  style={inputStyle}
                  onChangeText={txt => this.setState({ phoneNum: txt })}
                  value={this.state.phoneNum}
                  placeholderTextColor={PRIMARY_COLOR}
                  keyboardType='phone-pad'
                  placeholder="No. Handphone Pemesan" />
              </View>
              <View style={{ height: borderWidth, backgroundColor: PRIMARY_COLOR }} />
              <View style={{ padding: 5 }}>
                <TextInput
                  style={inputStyle2}
                  onChangeText={txt => this.setState({ address: txt })}
                  value={this.state.address}
                  multiline={true}
                  placeholderTextColor={PRIMARY_COLOR}
                  placeholder="Alamat Tujuan" />
              </View>
              <View style={{ height: borderWidth, backgroundColor: PRIMARY_COLOR }} />
              <View style={{ padding: 5 }}>
                <TextInput
                  style={inputStyle2}
                  onChangeText={txt => this.setState({ notes: txt })}
                  value={this.state.notes}
                  multiline={true}
                  placeholderTextColor={PRIMARY_COLOR}
                  placeholder="Catatan (alergi, dll)" />
              </View>
            </View>
          </ScrollView>
        </View>
        <View style={{ paddingVertical: 32, paddingHorizontal: 64 }}>
          <Button disabled={!enableNext} title='Lanjutkan' onPress={() => this.next()} />
        </View>
      </View>
    );

  }
}

export default Page;
