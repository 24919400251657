import React from 'react';
import moment from 'moment';
import feathersLib from '../feathersLib';
import Lib from '../Lib';
import { TouchableHighlight, View, Text, ScrollView, Image } from 'react-native';
import Button from './Button';
import HeaderBar from './HeaderBar';
import Modal from 'modal-enhanced-react-native-web';
import WideMenu from './WideMenu';
import cartLib from '../cartLib';

const { PRIMARY_COLOR, LIGHT_GREEN_COLOR, ORANGE_COLOR, LIGHT_ORANGE_COLOR, TEXT_GREY_COLOR } = require('./Data');

const SUCCESS_IMG = require('../img/success.png');
const FAIL_IMG = require('../img/confirmfail.png');
const CLOSE = require('../img/close.png');

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: 'busy',
      modalConfirmVisible: false,
      modalResultVisible: false
    };
  }

  async componentDidMount() {
    const { width, height } = Lib.getScreenSize();
    this.setState({ sw: width, sh: height });

    try {
      let saleItems = await feathersLib.findSaleItems();
      saleItems = saleItems.data;
      const id2img = {};
      for (let i = 0; i < saleItems.length; i++) {
        const id = saleItems[i]._id;
        const linkImage = saleItems[i].linkImage;
        id2img[id] = linkImage;
      }

      console.log({ id2img });
      this.setState({ id2img });
    } catch (err) {
      console.error(err);
    }

    try {
      await this.refreshInvoices();
    } catch (err) {
      console.error(err);
    }

  }

  async logOut() {
    await feathersLib.logOut();
    await cartLib.resetData();
    await cartLib.resetOrderData();
    this.props.history.push('/home');
  }

  contactUs() {
    this.props.history.push('/contact-us');
  }

  back() {
    this.props.history.goBack();
  }

  async refreshInvoices() {
    const token = await Lib.getStorageValue('GREENS_TOKEN');
    await feathersLib.loginByAccessToken(token);
    const invoices = await feathersLib.getInvoices();
    this.setState({ rows: invoices.data });
  }

  confirm(item) {
    this.setState({ modalConfirmVisible: true, selectedItem: item, confirmType: 'confirm' });
  }

  async confirmConfirm() {
    this.setState({ modalConfirmVisible: false });
    const item = this.state.selectedItem;
    try {
      const id = item._id;
      await feathersLib.confirmInvoice(id);
      await this.refreshInvoices();
      this.setState({ modalResultVisible: true, resultSuccess: true });
    } catch (err) {
      console.error(err);
      this.setState({ modalResultVisible: true, resultSuccess: false });
    }
  }

  cancel(item) {
    this.setState({ modalConfirmVisible: true, selectedItem: item, confirmType: 'cancel' });
  }

  async confirmCancel() {
    this.setState({ modalConfirmVisible: false });
    const item = this.state.selectedItem;
    try {
      const id = item._id;
      await feathersLib.removeInvoice(id);
      await this.refreshInvoices();
      this.setState({ modalResultVisible: true, resultSuccess: true });
    } catch (err) {
      console.error(err);
      this.setState({ modalResultVisible: true, resultSuccess: false });
    }
  }

  payment(item) {
    console.log('payment');
    Lib.redirectToUrl(item.snapUrl);
  }

  renderCardMenu(item, index) {
    const id2img = this.state.id2img;
    const id = item._id;
    const linkImage = id2img[id];
    item.linkImage = linkImage;
    return (
      <View style={{ paddingBottom: 20 }}>
        <WideMenu item={item} amount={item.amount} viewOnly={true} />
      </View>
    );
  }

  renderConfirm() {
    const borderColor = PRIMARY_COLOR;
    const viewStyle0 = { backgroundColor: PRIMARY_COLOR, padding: 5, alignItems: 'center', justifyContent: 'center', height: 40 };
    const viewStyle1 = { padding: 5, alignItems: 'center', justifyContent: 'center', height: 40 };
    const textStyle0 = { color: 'white', textAlign: 'center', fontSize: 14, fontWeight: 600 };
    // const textStyle1 = { color: TEXT_GREY_COLOR, textAlign: 'center', fontSize: 20, fontWeight: 600 };
    const textStyle2 = { color: TEXT_GREY_COLOR, textAlign: 'center', fontSize: 14, fontWeight: 600 };
    const textStyle3 = { color: PRIMARY_COLOR, textAlign: 'center', fontSize: 20, fontWeight: 600 };
    const borderWidth = 2;

    let invoiceId;
    let name;
    let phoneNum;
    let address;
    let notes;
    let price;
    let createdAt;
    let rows = [];
    let paid = false;

    const data = this.state.selectedItem;

    if (data) {
      createdAt = moment(data.createdAt).format('DD MMMM YYYY HH:mm');
      invoiceId = data.invoiceId;
      name = data.name;
      phoneNum = data.phoneNum;
      address = data.address;
      notes = data.notes;
      let priceNum = Number(data.totalPrice);
      price = Lib.idrAmount(priceNum);
      rows = data.items;
      paid = data.paid;
    }

    let confirmMsg;
    let btns;

    if (!paid) {
      if (this.state.confirmType === 'confirm') {
        confirmMsg = 'Setelah pembayaran terkonfirmasi, kami akan memproses pesanan anda.';
        btns = (
          <View>
            <Text style={{ textAlign: 'center', color: '#FFBB55', fontSize: 14, fontWeight: 700 }}>Menunggu Pembayaran</Text>
            <Text> </Text>
            <Text style={{ textAlign: 'center', color: PRIMARY_COLOR, fontSize: 14, fontWeight: 400 }}>{confirmMsg}</Text>
            <Text> </Text>
            <View style={{ flexDirection: 'row' }}>
              <View style={{ flex: 1 }}><Button title='Saya Sudah Membayar' onPress={() => this.confirmConfirm()} /></View>
            </View>
          </View>
        );
      } else if (this.state.confirmType === 'cancel') {
        confirmMsg = 'Anda yakin ingin membatalkan pesanan berikut?';
        btns = (
          <View>
            <Text style={{ textAlign: 'center', color: '#FFBB55', fontSize: 14, fontWeight: 700 }}>Menunggu Pembayaran</Text>
            <Text> </Text>
            <Text style={{ textAlign: 'center', color: PRIMARY_COLOR, fontSize: 14, fontWeight: 400 }}>{confirmMsg}</Text>
            <Text> </Text>
            <View style={{ flexDirection: 'row' }}>
              <View style={{ flex: 1 }}><Button type='outline' title='Ya' onPress={() => this.confirmCancel()} /></View>
              <View style={{ width: 10 }} />
              <View style={{ flex: 1 }}><Button title='Tidak' onPress={() => this.setState({ modalConfirmVisible: false })} /></View>
            </View>
          </View>
        );
      }
    }

    return (
      <View style={{ flex: 1, paddingBottom: 32 }}>
        <View style={{ paddingHorizontal: 16, paddingVertical: 16, alignItems: 'flex-end' }}>
          <TouchableHighlight onPress={() => this.setState({ modalConfirmVisible: false })}>
            <Image source={{ uri: CLOSE }} style={{ height: 24, width: 24, resizeMode: 'stretch' }} />
          </TouchableHighlight>
        </View>
        <ScrollView style={{ flex: 1, paddingHorizontal: 32 }}>
          <View style={{ borderWidth: borderWidth, borderRadius: 8, borderColor: borderColor, paddingVertical: 0 }}>
            <View style={viewStyle0}>
              <Text style={textStyle0}>{createdAt}</Text>
            </View>
            <View style={{ height: borderWidth, backgroundColor: borderColor }} />
            <View style={viewStyle1}>
              <Text style={textStyle2}>{invoiceId}</Text>
            </View>
            <View style={{ height: borderWidth, backgroundColor: borderColor }} />
            <View style={viewStyle1}>
              <Text style={textStyle2}>{name + ' (' + phoneNum + ')'}</Text>
            </View>
            <View style={{ height: borderWidth, backgroundColor: borderColor }} />
            <View style={viewStyle1}>
              <Text style={textStyle2}>{address}</Text>
            </View>
            <View style={{ height: borderWidth, backgroundColor: borderColor }} />
            <View style={viewStyle1}>
              <Text style={textStyle2}>{notes}</Text>
            </View>
          </View>
          <Text> </Text>
          {rows.map((item, index) => this.renderCardMenu(item, index))}
        </ScrollView>
        <View style={{ paddingHorizontal: 32 }}>
          <Text> </Text>
          <View style={{ backgroundColor: '#FFE5BE', borderRadius: 8, paddingVertical: 8, borderWidth: 2, borderColor: PRIMARY_COLOR }}>
            <View style={viewStyle1}>
              <Text style={textStyle3}>{price}</Text>
            </View>
          </View>
          <Text> </Text>
          {btns}
        </View>
      </View>
    );
  }

  renderModalConfirm() {
    const sw = this.state.sw * 0.8;
    const sh = this.state.sh * 0.8;
    return (
      <Modal
        isVisible={this.state.modalConfirmVisible}
        onBackdropPress={() => this.setState({ modalConfirmVisible: false })}
      >
        <View style={{ alignItems: 'center', justifyContent: 'center' }}>
          <View style={{ backgroundColor: 'white', width: sw, height: sh, borderRadius: 16, overflow: 'hidden' }}>
            {this.renderConfirm()}
          </View>
        </View>
      </Modal>
    );
  }

  renderModalResult() {
    const success = this.state.resultSuccess;
    const sw = this.state.sw * 0.8;
    // const sh = this.state.sh * 0.8;
    const iw = sw - 32;
    const textStyle3 = { color: PRIMARY_COLOR, textAlign: 'center', fontSize: 14, fontWeight: 600 };
    const textStyle4 = { color: '#046938', textAlign: 'center', fontSize: 14, fontWeight: 600 };

    const imgSuccess = <Image resizeMode='contain' source={{ uri: SUCCESS_IMG }} style={{ width: iw, height: iw }} />;
    const imgFail = <Image resizeMode='contain' source={{ uri: FAIL_IMG }} style={{ width: iw, height: iw }} />;
    const img = success ? imgSuccess : imgFail;

    let bottom;
    if (!success) {
      const item = this.state.selectedItem;
      let invoiceId = item ? item.invoiceId : '';
      bottom = (
        <View style={{ padding: 32 }}>
          <Text style={textStyle3}>Silahkan hubungi tim support kami</Text>
          <Text> </Text>
          <Text style={textStyle4}>Kode Invoice</Text>
          <Text style={textStyle4}>{invoiceId}</Text>
          <Text> </Text>
          <Button title='Whatsapp +6281211009589' onPress={() => Lib.openUrl('https://wa.me/+6281211009589')} />
        </View>
      );
    } else {
      bottom = (
        <View style={{ padding: 32 }}>
          <Text style={textStyle3}>Pembayaran Anda Telah Selesai</Text>
          <Text> </Text>
        </View>
      );
    }
    return (
      <Modal
        isVisible={this.state.modalResultVisible}
        onBackdropPress={() => this.setState({ modalResultVisible: false })}
      >
        <View style={{ alignItems: 'center', justifyContent: 'center' }}>
          <View style={{ backgroundColor: 'white', width: sw, borderRadius: 16, overflow: 'hidden', padding: 0 }}>
            <View style={{ paddingHorizontal: 32, paddingTop: 32, alignItems: 'flex-end' }}>
              <TouchableHighlight onPress={() => this.setState({ modalResultVisible: false })}>
                <Image source={{ uri: CLOSE }} style={{ height: 24, width: 24, resizeMode: 'stretch' }} />
              </TouchableHighlight>
            </View>
            <View style={{ flex: 1, paddingRight: 32, alignItems: 'center', justifyContent: 'center' }}>
              {img}
            </View>
            {bottom}
          </View>
        </View>
      </Modal>
    );
  }

  renderCard(item, index) {
    const space = 16;
    // const invoiceCode = item.invoiceId;
    let invoiceStatus = 'Lunas'; // Berjalan, Menunggu Pembayaran

    const address = item.address;
    const createdAt = moment(item.createdAt).format('DD MMMM YYYY HH:mm');

    const items = item.items;

    let color = PRIMARY_COLOR;
    let lightColor = LIGHT_GREEN_COLOR;
    let pending = !(item.paid);
    let pendingActions = null;

    if (pending || item.status !== 'DONE') {
      color = ORANGE_COLOR;
      lightColor = LIGHT_ORANGE_COLOR;
      invoiceStatus = 'Menunggu Pembayaran';
      if(!pending) invoiceStatus = 'Lunas - Sedang Diproses';
    } else {
      invoiceStatus = 'Siap Diambil';
    }

    const titleStyle = { color: color, fontWeight: 'bold', marginBottom: 5 };
    const textStyle = { color: color };
    const linkStyle = { color: color, textDecoration: 'underline' };

    if (pending) {
      pendingActions = (
        <View style={{ paddingTop: space, justifyContent: 'space-between', flexDirection: 'row' }}>
          <TouchableHighlight onPress={() => this.payment(item)}><Text style={linkStyle}>Selesaikan Pembayaran</Text></TouchableHighlight>
          <TouchableHighlight onPress={() => this.cancel(item)}><Text style={linkStyle}>Batalkan</Text></TouchableHighlight>
        </View>
      );
    }

    let numOrder = 0;
    for (let i = 0; i < items.length; i++) {
      numOrder += items[i].amount;
    }

    return (
      <View style={{ paddingBottom: 20 }}>
        <View style={{ backgroundColor: lightColor, borderRadius: 16, overflow: 'hidden' }}>
          <View style={{ backgroundColor: color, padding: space, flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ flex: 1 }}>
              <Text style={{ color: 'white', fontWeight: 'bold' }}>{createdAt}</Text>
            </View>
            <View style={{}}>
              <Text style={{ color: 'white', textAlign: 'right' }}>{invoiceStatus}</Text>
            </View>
          </View>
          <View style={{ padding: space }}>
            <View style={{ paddingBottom: space, borderBottomWidth: 1, borderBottomColor: color }}>
              <Text style={titleStyle}>Alamat</Text>
              <Text style={textStyle}>{address}</Text>
            </View>
            <View style={{ paddingTop: space, flexDirection: 'row', justifyContent: 'space-between' }}>
              <Text style={titleStyle}>{numOrder + ' Pesanan'}</Text>
              <TouchableHighlight onPress={() => this.confirm(item)}><Text style={linkStyle}>Lihat Detail</Text></TouchableHighlight>
            </View>
            {pendingActions}
          </View>
        </View>
      </View>
    );
  }

  render() {
    const rows = this.state.rows ? this.state.rows : [];
    let msg = 'Clear Cache';
    if (feathersLib.phoneNum) msg = 'Log Out';
    return (
      <View style={{ flex: 1 }}>
        {this.renderModalConfirm()}
        {this.renderModalResult()}
        <HeaderBar title='Riwayat Pemesanan' goBack={() => this.back()} />
        <View style={{ flex: 1 }}>
          <ScrollView style={{ flex: 1, padding: 32 }}>
            {rows.map((item, index) => this.renderCard(item, index))}
          </ScrollView>
        </View>
        <View style={{ paddingVertical: 32, paddingHorizontal: 64 }}>
          <Button type='outline' title={msg} onPress={() => this.logOut()} />
          <View style={{ height: 20 }} />

          <Button title='Contact Us' onPress={() => this.contactUs()} />
        </View>
      </View>
    );

  }
}

export default Page;
