import io from 'socket.io-client';
import feathers from '@feathersjs/client';
import axios from 'axios';
import moment from 'moment';
import Lib from './Lib';

const { URL_API, URL_MIDTRANS } = require('./config');

console.log('** version 1.0 **');
console.log('URL API:', URL_API);
console.log('URL MIDTRANS:', URL_MIDTRANS);

const URL_MENU = URL_API;
const URL = URL_MIDTRANS;


const socket = io(URL);
const app = feathers();

app.configure(feathers.socketio(socket, {
  timeout: 180000
}));

app.configure(feathers.authentication());

class Singleton {
  constructor() {
    this.user = false;
  }

  getUrl() {
    return URL;
  }

  async generateRandomUser() {
    const password = 'guest' + moment().valueOf();
    const email = password + '@email.com';
    await this.register(email, password);
    await Lib.setStorageValue('GREENS_ORDER_TMP', password);
    return { email, password };
  }

  async logOut() {
    this.user = false;
    this.phoneNum = false;
    await Lib.clearStorageValue();
  }

  async autoLogin() {
    if (this.user) return;

    try {
      const token = await Lib.getStorageValue('GREENS_TOKEN');
      // console.log({ token });
      if (token && token.length > 0) {
        return await this.loginByAccessToken(token);
      }
    } catch (err) {
      // await Lib.clearStorageValue();
      console.error(err);
      throw err;
    }

    // try {
    //   let password = await Lib.getStorageValue('GREENS_ORDER_TMP');
    //   let email;
    //   if (!password || password.length === 0) {
    //     // console.log('generate random user..');
    //     const result = await this.generateRandomUser();
    //     email = result.email;
    //     password = result.password;
    //   } else {
    //     email = password + '@email.com';
    //     // console.log({ email })
    //   }
    //   await this.loginByEmailPassword(email, password);
    // } catch (err) {
    //   // await Lib.clearStorageValue();
    //   console.error(err);
    //   throw err;
    // }
  }

  async register(email, password) {
    const result = await axios.post(URL + '/users', {
      email,
      password
    });
    return result;
  }

  async loginByAccessToken(accessToken) {
    // console.log('loginByAccessToken...');
    const result = await app.authenticate({
      strategy: 'jwt',
      accessToken: accessToken
    });
    this.user = result.user;
    // console.log(this.user);
    const email = this.user.email;
    if (email && email.indexOf('guest') < 0) {
      this.phoneNum = email.split('@')[0];
      // console.log({ phoneNum: this.phoneNum });
    }
  }

  async loginByEmailPassword(email, password) {
    const result = await app.authenticate({
      strategy: 'local',
      email,
      password
    });

    this.user = result.user;

    return result.accessToken;
  }

  async getUserProfile() {
    const up = app.service('user-profile');
    const ret = await up.find({
      query: {
        createdBy: this.user._id
      }
    });

    if (ret.total > 0) return (ret.data[0]);
    console.log(ret);
    return {};
  }

  async patchUser(id, data) {
    const users = app.service('users');
    const ret = await users.patch(id, data);
    return ret;
  }

  async createInvoice(data) {
    if (!data.name) throw new Error('name not defined!!');
    const iService = app.service('invoice');
    const result = await iService.create(data);
    return result;
  }

  async getInvoices() {
    const iService = app.service('invoice');
    const query = {
      $sort: {
        createdAt: -1
      },
      $limit: 1000
    };
    const result = await iService.find({ query });
    return result;
  }

  async getInvoicesPaid() {
    const iService = app.service('invoice');
    const query = {
      paid: true,
      $sort: {
        createdAt: -1
      }
    };
    const result = await iService.find({ query });
    return result;
  }

  async getInvoicesNotPaid() {
    const iService = app.service('invoice');
    const query = {
      paid: false,
      transactionStatus: {
        $ne: 'failure'
      },
      $sort: {
        createdAt: -1
      }
    };
    const result = await iService.find({ query });
    return result;
  }

  async confirmInvoice(id) {
    const iService = app.service('invoice');
    const result = await iService.patch(id, { paid: true });
    return result;
  }

  async removeInvoice(id) {
    const iService = app.service('invoice');
    const result = await iService.remove(id);
    return result;
  }

  async getAllInvoicesPaid(limit, skip) {
    const iService = app.service('invoice');
    const query = {
      paid: true,
      $limit: limit,
      $skip: skip,
      $sort: {
        createdAt: -1
      }
    };
    const result = await iService.find({ query });
    return result;
  }

  async getAllInvoices(limit, skip) {
    const iService = app.service('invoice');
    const query = {
      $limit: 1000,
      $skip: 0,
      $sort: {
        createdAt: -1
      }
    };
    const result = await iService.find({ query });
    return result;
  }

  async getAllMessages(limit, skip) {
    const iService = app.service('message');
    const query = {
      $limit: 1000,
      $skip: 0,
      $sort: {
        createdAt: -1
      }
    };
    const result = await iService.find({ query });
    return result;
  }

  // async getAllInvoicesPaid(limit, skip, filter) {
  //   const iService = app.service('invoice');
  //   let query = {
  //     paid: true,
  //     $limit: limit,
  //     $skip: skip,
  //     $sort: {
  //       createdAt: -1
  //     }
  //   };

  //   if (filter && filter !== 'ALL') {
  //     let delta = 0;

  //     if (Number(filter) === -1) delta = -7;
  //     else if (Number(filter) === 1) delta = 7;

  //     const m1 = moment().day(1 + delta).startOf('day').format();
  //     const m2 = moment().day(1 + 5 + delta).endOf('day').format();
  //     const m3 = moment().day(1 - (7 * 3) + delta).startOf('day').format();
  //     const m4 = moment().day(1 + 5 + delta).endOf('day').format();

  //     query = Object.assign({
  //       $or: [
  //         {
  //           duration: "5",
  //           startDate: {
  //             $gte: m1,
  //             $lte: m2
  //           }
  //         },
  //         {
  //           duration: "20",
  //           startDate: {
  //             $gt: m3,
  //             $lt: m4
  //           }
  //         }
  //       ],
  //     }, query);

  //   }

  //   const result = await iService.find({ query });
  //   return result;
  // }

  async createMessage(data) {
    const iService = app.service('message');
    const result = await iService.create(data);
    return result;
  }

  async removeMessage(id) {
    const iService = app.service('message');
    const result = await iService.remove(id);
    return result;
  }

  async createPhoneOTP(phoneNum) {
    const iService = app.service('phone-otp');
    const result = await iService.create({ phoneNum });
    return result;
  }

  async verifyPhoneOTP(phoneNum, otp) {
    const iService = app.service('phone-otp');
    const result = await iService.create({ phoneNum, otp });
    return result;
  }

  async verifyPhonePassword(phoneNum, secret) {
    const iService = app.service('phone-otp');
    const result = await iService.create({ phoneNum, secret });
    console.log(result);
    return result;
  }

  async findSaleItems() {
    const result = await axios.get(URL_MENU + '/menu-template');
    return result.data;
  }

}

export default new Singleton();

