import React from 'react';
import { TouchableHighlight, View, Text, Image } from 'react-native';
// import { Button, DatePicker, version } from "antd";
import feathersLib from '../feathersLib';
// import Lib from '../Lib';
import cartLib from '../cartLib';


import {
  MenuOutlined, CloseOutlined
} from '@ant-design/icons';

const BACK = require('../img/back.png');
const BACK_WHITE = require('../img/back_white.png');
const LOGO = require('../img/logo.png');
const COIN = require('../img/coin.png');
const HISTORY = require('../img/history.png');
const CART = require('../img/cart.png');
const { PRIMARY_COLOR } = require('./Data');

class HeaderBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: 'busy',
      showMenu: false
    };
  }

  async componentDidMount() {
  }

  componentWillMount() {
  }

  nextPage(next) {
    this.props.history.push(next);
  }

  async logout() {
    // TODO
    await feathersLib.logOut();
    await cartLib.resetData();
    await cartLib.resetOrderData();
    window.location.reload();
  }

  render() {
    let numCoin = null;
    if (this.props.numCoin && this.props.numCoin.length > 0) {
      numCoin = (
        <View style={{ flexDirection: 'row' }}>
          <Image source={{ uri: COIN }} style={{ height: 16, width: 16, resizeMode: 'stretch' }} />
          <Text style={{ color: PRIMARY_COLOR, fontWeight: 700, fontSize: 13 }}> {this.props.numCoin}</Text>
        </View>
      );
    }

    let right = <Text> </Text>;
    if (this.props.history) {

    }

    if (this.props.showHistory) {
      right = (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <TouchableHighlight onPress={() => this.props.onPressHistory()}>
            <Image source={{ uri: HISTORY }} style={{ height: 24, width: 24, resizeMode: 'stretch' }} />
          </TouchableHighlight>
          <View style={{ width: 24 }} />
          <TouchableHighlight onPress={() => this.props.onPressCart()}>
            <Image source={{ uri: CART }} style={{ height: 24, width: 24, resizeMode: 'stretch' }} />
          </TouchableHighlight>
        </View>
      );
    } else if (this.props.showCart) {
      right = (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <TouchableHighlight onPress={() => this.props.onPressCart()}>
            <Image source={{ uri: CART }} style={{ height: 24, width: 24, resizeMode: 'stretch' }} />
          </TouchableHighlight>
        </View>
      );
    }

    if (this.props.showMenu) {
      right = (
        <TouchableHighlight onPress={() => this.setState({ showMenu: true })}>
          <View>
            <MenuOutlined style={{ color: PRIMARY_COLOR, fontSize: 20 }} />
          </View>
        </TouchableHighlight>
      );
    }

    if (this.state.showMenu) {
      right = (
        <TouchableHighlight onPress={() => this.setState({ showMenu: false })}>
          <View>
            <CloseOutlined style={{ color: PRIMARY_COLOR, fontSize: 20 }} />
          </View>
        </TouchableHighlight>
      );
    }

    let textColor = PRIMARY_COLOR;
    if (this.props.type === 'green') textColor = 'white';

    const title = this.props.title
    let logo = <Image source={{ uri: LOGO }} style={{ height: 44, width: 140, resizeMode: 'contain' }} />;
    if (title) logo = <Text style={{ color: textColor, fontSize: 20, fontWeight: 700, textAlign: 'center' }}>{title}</Text>;
    let back = null;

    if (this.props.goBack) {
      if (this.props.type === 'green') {
        back = (
          <TouchableHighlight onPress={() => this.props.goBack()}>
            <Image source={{ uri: BACK_WHITE }} style={{ height: 44, width: 10, resizeMode: 'contain' }} />
          </TouchableHighlight>
        );
      } else {
        back = (
          <TouchableHighlight onPress={() => this.props.goBack()}>
            <Image source={{ uri: BACK }} style={{ height: 44, width: 10, resizeMode: 'contain' }} />
          </TouchableHighlight>
        );
      }
    }

    let ts = { color: 'white', fontWeight: 700 };
    let rowstyle = { borderBottomWidth: 1, borderColor: 'gainsboro', height: 64, paddingHorizontal: 12, justifyContent: 'center' };
    let menus = null;

    if (this.state.showMenu && this.props.isLogin) {
      menus = (
        <View style={{ backgroundColor: PRIMARY_COLOR }}>
          <View style={rowstyle}>
            <TouchableHighlight onPress={() => this.nextPage('/term-and-condition')}>
              <Text style={ts}>Syarat & Ketentuan</Text>
            </TouchableHighlight>
          </View>
          <View style={rowstyle}>
            <TouchableHighlight onPress={() => this.nextPage('/refund-policy')}>
              <Text style={ts}>Kebijakan Privasi</Text>
            </TouchableHighlight>
          </View>
          <View style={rowstyle}>
            <TouchableHighlight onPress={() => this.nextPage('/order-history')}>
              <Text style={ts}>Riwayat Pesanan</Text>
            </TouchableHighlight>
          </View>
          <View style={rowstyle}>
            <TouchableHighlight onPress={() => this.nextPage('/my-profile')}>
              <Text style={ts}>Profil Saya</Text>
            </TouchableHighlight>
          </View>
          <View style={rowstyle}>
            <TouchableHighlight onPress={() => this.logout()}>
              <Text style={ts}>Log Out</Text>
            </TouchableHighlight>
          </View>
        </View>
      );
    } else if (this.state.showMenu) {
      menus = (
        <View style={{ backgroundColor: PRIMARY_COLOR }}>
          <View style={rowstyle}>
            <TouchableHighlight onPress={() => this.nextPage('/term-and-condition')}>
              <Text style={ts}>Syarat & Ketentuan</Text>
            </TouchableHighlight>
          </View>
          <View style={rowstyle}>
            <TouchableHighlight onPress={() => this.nextPage('/refund-policy')}>
              <Text style={ts}>Kebijakan Privasi</Text>
            </TouchableHighlight>
          </View>
        </View>
      );
    }

    return (
      <View>
        <View style={{ flexDirection: 'row', height: 64, borderBottomColor: 'gainsboro', borderBottomWidth: 1 }}>
          <View style={{ width: 64, alignItems: 'flex-start', justifyContent: 'center', paddingLeft: 12 }}>
            {back}
            {numCoin}
          </View>
          <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
            {logo}
          </View>
          <View style={{ width: 64, alignItems: 'flex-end', justifyContent: 'center', paddingRight: 12 }}>
            {right}
          </View>
        </View>
        {menus}
      </View>
    );

  }
}

export default HeaderBar;
