import React from 'react';
import Lib from '../Lib';
import { View, Text, Image } from 'react-native';
import SetNumber from './SetNumber';

const { PRIMARY_COLOR } = require('./Data');

class WideMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: 'busy'
    };
  }

  async componentDidMount() {
  }

  componentWillUnmount() {
  }

  setItemNum(delta) {
    const originalValue = this.props.amount;;
    let nextValue = originalValue + Number(delta);
    if (nextValue < 0) nextValue = 0;
    this.props.onChange(nextValue);
  }

  render() {
    const item = this.props.item;
    let uri = 'https://via.placeholder.com/160';
    if (item.linkImage && item.linkImage.length > 0) uri = item.linkImage;
    const title = item.title.toUpperCase();
    const price = Lib.idrAmount(item.price);

    return (
      <View style={{ backgroundColor: 'white', borderColor: 'gainsboro', borderWidth: 1, borderRadius: 15, flexDirection: 'row' }}>
        <Image source={{ uri: uri }} style={{ width: 140, height: 140, borderTopLeftRadius: 15, borderBottomLeftRadius: 15, resizeMode: 'cover' }} />
        <View style={{ paddingVertical: 10, paddingHorizontal: 20, flex: 1 }}>
          <View style={{ flex: 1 }}>
            <Text style={{ color: PRIMARY_COLOR, fontSize: 12, fontWeight: '700', marginBottom: 5 }}>{title}</Text>
            <Text style={{ color: PRIMARY_COLOR, fontSize: 12, fontWeight: '400' }}>{price}</Text>
          </View>
          <View style={{ alignItems: 'flex-end' }}>
            <SetNumber value={this.props.amount} onChange={delta => this.setItemNum(delta)} viewOnly={this.props.viewOnly} />
          </View>
        </View>
      </View>
    );
  }

}

export default WideMenu;
