import React from 'react';
import { TouchableHighlight, Text, View, Image } from 'react-native';

const { PRIMARY_COLOR } = require('./Data');
const PLUS = require('../img/plus.png');
const MINUS = require('../img/minus.png');

class SetNumber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      num: 0
    };

  }

  async componentDidMount() {
  }

  componentWillMount() {
  }

  // click() {
  //   if (this.props.disabled) return;
  //   this.props.onPress();
  // }

  click(toAdd) {
    if (this.props.onChange) this.props.onChange(toAdd);
    // let num = this.state.num;
    // num = num + Number(toAdd);
    // if (num < 0) num = 0;
    // this.setState({ num });
  }

  getNum() {
    return this.state.num;
  }

  render() {
    let num = this.props.value ? this.props.value : 0;
    const r = 32;

    if (this.props.viewOnly) {
      return (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View style={{ paddingHorizontal: 10 }}>
            <View style={{ backgroundColor: PRIMARY_COLOR, borderRadius: 16, width: r, height: r, alignItems: 'center', justifyContent: 'center' }}>
              <Text style={{ color: 'white' }}>{num}</Text>
            </View>
          </View>
        </View>
      );
    }

    return (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <TouchableHighlight onPress={() => this.click(-1)}>
          <Image source={{ uri: MINUS }} style={{ height: 24, width: 24, resizeMode: 'stretch' }} />
        </TouchableHighlight>
        <View style={{ paddingHorizontal: 10 }}>
          <View style={{ backgroundColor: PRIMARY_COLOR, borderRadius: 16, width: r, height: r, alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ color: 'white' }}>{num}</Text>
          </View>
        </View>
        <TouchableHighlight onPress={() => this.click(+1)}>
          <Image source={{ uri: PLUS }} style={{ height: 24, width: 24, resizeMode: 'stretch' }} />
        </TouchableHighlight>
      </View>
    );

  }
}

export default SetNumber;
