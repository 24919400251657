import React from 'react';
// import moment from 'moment';
import feathersLib from '../feathersLib';
import Lib from '../Lib';
import cartLib from '../cartLib';
// import Swal from 'sweetalert2';
import { ActivityIndicator, TouchableHighlight, View, Text, ScrollView, Image, CheckBox } from 'react-native';
import Button from './Button';
import HeaderBar from './HeaderBar';
import WideMenu from './WideMenu';

const COIN = require('../img/coin.png');
const { PRIMARY_COLOR } = require('./Data');

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: 'form',
      agree: false,
      cartData: { total: 0 },
      isLogin: false,
      numCoin: 0,
      numCoinUsed: 0
    };
  }

  async componentDidMount() {
    const data = await cartLib.loadOrderData();
    const cartData = await cartLib.loadData();
    this.setState({ data, cartData });

    const token = await Lib.getStorageValue('GREENS_TOKEN');
    try {
      if (token && token.length > 0) {
        await feathersLib.loginByAccessToken(token);
        let numCoin = 0;
        if (feathersLib.user && feathersLib.user.points && feathersLib.user.points > 0) numCoin = feathersLib.user.points;
        const user = feathersLib.user;
        data.name = user.name;
        data.address = user.address;
        data.phoneNum = user.phoneNum;
        data.notes = user.notes;
        this.setState({ isLogin: true, numCoin, data });
      } else {
        throw new Error('token fail');
      }
    } catch (err) {
      console.error(err);
      this.setState({ isLogin: false });
    }
  }

  componentWillMount() {
  }

  async finish() {
    try {
      const data = this.state.data;
      // const cartData = this.state.cartData;
      const numCoinUsed = this.state.numCoinUsed;
      const isLogin = this.state.isLogin;
      const items = await cartLib.getItems();

      this.setState({ show: 'busy' });

      const newInvoice = {
        name: data.name,
        phoneNum: data.phoneNum,
        address: data.address,
        notes: data.notes ? data.notes : '',
        numCoinUsed: 0,
        items: items
      };

      if (isLogin) {
        newInvoice.numCoinUsed = numCoinUsed;
      } else {
      }

      // await feathersLib.autoLogin();
      // console.log(newInvoice);
      const invoice = await feathersLib.createInvoice(
        newInvoice
      );

      console.log({ invoice });
      this.setState({ show: 'form' });

      await cartLib.resetData();

      if (invoice && !invoice.paid) {
        const snapUrl = invoice.snapUrl;
        Lib.redirectToUrl(snapUrl);
      } else {
        // todo
      }
      this.backHome();
    } catch (err) {
      console.error(err);
      this.setState({ show: 'form' });
    }

  }

  refundPolicy() {
    this.props.history.push('/term-and-condition');
  }

  backHome() {
    this.props.history.push('/');
  }

  back() {
    this.props.history.goBack();
  }

  agreeForPolicy(ok) {
    this.setState({ agree: ok });
  }

  agreeToUsePoints(ok) {
    let numCoinUsed = this.state.numCoin;
    const numCoinMax = Math.floor(this.state.cartData.total / 1000);
    if (numCoinUsed > numCoinMax) numCoinUsed = numCoinMax;
    if (!ok) numCoinUsed = 0;
    this.setState({ agreeUsePoints: ok, numCoinUsed });
  }

  async setNewAmount(item, newAmount) {
    const cartData = await cartLib.setItemAmount(item, newAmount);
    this.setState({ cartData });
  }

  renderCard(item, index) {
    return (
      <View style={{ paddingBottom: 20 }}>
        <WideMenu item={item} amount={item.amount} viewOnly={true} onChange={newAmount => this.setNewAmount(item, newAmount)} />
      </View>
    );
  }

  render() {

    const borderColor = PRIMARY_COLOR;
    const viewStyle1 = { padding: 5, alignItems: 'center', justifyContent: 'center', height: 40 };

    const textStyle1 = { color: borderColor, textAlign: 'center', fontSize: 20, fontWeight: 600 };
    const textStyle2 = { color: borderColor, textAlign: 'center', fontSize: 14, fontWeight: 600 };
    const textStyle3 = { color: PRIMARY_COLOR, textAlign: 'center', fontSize: 20, fontWeight: 600 };
    const textStyle4 = { color: borderColor, textAlign: 'left', fontSize: 14, fontWeight: 600 };
    const borderWidth = 2;

    const data = this.state.data;
    let name;
    let phoneNum;
    let address;
    let notes;
    let price;

    const numCoin = this.state.numCoin;
    const numCoinUsed = Number(this.state.numCoinUsed);

    if (data) {
      name = data.name;
      phoneNum = data.phoneNum;
      address = data.address;
      notes = data.notes;
      price = Lib.idrAmount(this.state.cartData.total);
    }

    if (numCoinUsed > 0) {
      const total = this.state.cartData.total;
      const discount = numCoinUsed * 1000;
      const endTotal = total - discount;
      price = Lib.idrAmount(endTotal);
    }

    let btn;
    if (this.state.show === 'busy') btn = <ActivityIndicator />;
    else btn = <Button disabled={!(this.state.agree)} title='Selesaikan Pembayaran' onPress={() => this.finish()} />;

    const checkboxEnable = (this.state.show !== 'busy');
    const checkboxUsePointsEnable = (this.state.show !== 'busy' && numCoin > 0);

    const rows = [];
    const items = this.state.cartData && this.state.cartData.items ? this.state.cartData.items : {};
    for (let key of Object.keys(items)) {
      const item = items[key];
      rows.push(item);
    }

    let minusPoints = null;

    if (this.state.agreeUsePoints) {
      // calc minus points;
      const numCoinUsed = '-' + this.state.numCoinUsed;
      minusPoints = (
        <Text style={{ color: 'red', fontSize: 14, fontWeight: 600, marginLeft: 5 }}>{numCoinUsed}</Text>
      );
    }

    let showCoin = null;
    let isLogin = this.state.isLogin;
    isLogin = true;

    if (isLogin) {
      showCoin = (
        <View>
          <View style={{ borderWidth: borderWidth, borderRadius: 8, borderColor: borderColor, paddingVertical: 0, flexDirection: 'row' }}>
            <View style={{ padding: 10, flex: 1 }}>
              <Text style={textStyle4}>Use GREENS Point</Text>
              <View style={{ flexDirection: 'row', paddingTop: 5 }}>
                <Image source={{ uri: COIN }} style={{ height: 16, width: 16, resizeMode: 'stretch', marginRight: 5 }} />
                <Text style={textStyle4}> {numCoin}</Text>
                {minusPoints}
              </View>
            </View>
            <View style={{ padding: 10, alignItems: 'center', justifyContent: 'center' }}>
              <CheckBox
                disabled={!checkboxUsePointsEnable}
                value={this.state.agreeUsePoints}
                onValueChange={e => this.agreeToUsePoints(e)}
                style={{ marginRight: 10, borderColor: PRIMARY_COLOR }}
              />
            </View>
          </View>
          <Text> </Text>
        </View>
      );
    }
    return (
      <View style={{ flex: 1 }}>
        <HeaderBar title='Konfirmasi' goBack={() => this.back()} />
        <View style={{ flex: 1 }}>
          <ScrollView style={{ flex: 1, padding: 32 }}>
            <View style={{ borderWidth: borderWidth, borderRadius: 8, borderColor: borderColor, paddingVertical: 0 }}>
              <View style={viewStyle1}>
                <Text style={textStyle1}>{name}</Text>
              </View>
              <View style={{ height: borderWidth, backgroundColor: borderColor }} />
              <View style={viewStyle1}>
                <Text style={textStyle2}>{phoneNum}</Text>
              </View>
              <View style={{ height: borderWidth, backgroundColor: borderColor }} />
              <View style={viewStyle1}>
                <Text style={textStyle2}>{address}</Text>
              </View>
              <View style={{ height: borderWidth, backgroundColor: borderColor }} />
              <View style={viewStyle1}>
                <Text style={textStyle2}>{notes}</Text>
              </View>
            </View>
            <Text> </Text>
            {showCoin}
            {rows.map((item, index) => this.renderCard(item, index))}
          </ScrollView>
        </View>
        <View style={{ paddingVertical: 32, paddingHorizontal: 32 }}>
          <View style={{ backgroundColor: '#E1FFD8', borderRadius: 8, paddingVertical: 8, borderColor: PRIMARY_COLOR, borderWidth: 1 }}>
            <View style={viewStyle1}>
              <Text style={textStyle3}>{price}</Text>
            </View>
          </View>
          <Text> </Text>
          <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <CheckBox
              disabled={!checkboxEnable}
              value={this.state.agree}
              onValueChange={e => this.agreeForPolicy(e)}
              style={{ marginRight: 10 }}
            />
            <Text style={{ color: borderColor }}>Saya menyetujui&nbsp;
              <TouchableHighlight onPress={() => this.refundPolicy()}>
                <Text style={{ color: borderColor, fontWeight: 'bold', textDecoration: 'underline' }}>Syarat dan Ketentuan GREEN</Text>
              </TouchableHighlight>
            </Text>
          </View>
          <View style={{ height: 20 }} />
          {btn}
        </View>
      </View>
    );

  }

}

export default Page;
