import React from 'react';
import Lib from '../Lib';
import cartLib from '../cartLib';
import feathersLib from '../feathersLib';
import { View, ScrollView } from 'react-native';
import Button from './Button';
import HeaderBar from './HeaderBar';
import WideMenu from './WideMenu';

// const { PRIMARY_COLOR, DARK_COLOR } = require('./Data');

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: 'busy',
      cartData: {
        items: {},
        numItems: 0,
        total: 0
      }
    };
  }

  async componentDidMount() {
    const token = await Lib.getStorageValue('GREENS_TOKEN');

    try {
      if (token && token.length > 0) {
        await feathersLib.loginByAccessToken(token);

        const name = feathersLib.user.name;
        const phoneNum = feathersLib.phoneNum;
        const profile = await feathersLib.getUserProfile();
        const address = profile.address;
        const notes = profile.notes;

        const data = {
          name: name,
          phoneNum: phoneNum,
          address: address,
          notes: notes
        }

        await cartLib.saveOrderData(data);

        this.setState({ isLogin: true });
      } else {
        throw new Error('token fail');;
      }
    } catch (err) {
      console.error('login error');
      console.error(err);
      this.setState({ isLogin: false });
    }

    try {
      let cartData = await cartLib.loadData();
      this.setState({ cartData });
    } catch (err) {
      console.error(err);
    }
  }

  componentWillMount() {
  }

  next() {
    if (this.state.isLogin) {
      this.props.history.push('/new-order-step-3');
    } else {
      this.props.history.push('/new-order-step-1');
    }
  }

  back() {
    this.props.history.goBack();
  }

  async setNewAmount(item, newAmount) {
    const cartData = await cartLib.setItemAmount(item, newAmount);
    this.setState({ cartData });
  }

  renderCard(item, index) {
    return (
      <View key={index} style={{ paddingBottom: 20 }}>
        <WideMenu item={item} amount={item.amount} onChange={newAmount => this.setNewAmount(item, newAmount)} />
      </View>
    );
  }

  render() {
    // const inputStyle = { height: 40, padding: 5, outlineWidth: 0 };
    // const inputStyle2 = { height: 80, padding: 5, outlineWidth: 0 };
    // const borderWidth = 2;

    // const name = this.state.name;
    // const phoneNum = this.state.phoneNum;
    // const address = this.state.address;

    // const enableNext = true;

    const rows = [];
    const items = this.state.cartData && this.state.cartData.items ? this.state.cartData.items : {};
    for (let key of Object.keys(items)) {
      const item = items[key];
      rows.push(item);
    }

    let btnOrder = <Button disabled={true} title='Beli Sekarang' onPress={() => console.log('do nothing')} />
    const cartData = this.state.cartData;
    const cartTotal = cartData.total;

    if (cartTotal > 0) {
      const title = 'Checkout - ' + Lib.idrAmount(cartTotal);
      btnOrder = <Button type='dark' title={title} onPress={() => this.next()} />;
    }


    return (
      <View style={{ flex: 1 }}>
        <HeaderBar title='Keranjang' goBack={() => this.back()} />
        <View style={{ flex: 1 }}>
          <ScrollView style={{ flex: 1, padding: 32 }}>
            {rows.map((item, index) => this.renderCard(item, index))}
          </ScrollView>
        </View>
        <View style={{ paddingVertical: 32, paddingHorizontal: 64 }}>
          {btnOrder}
          <View style={{ height: 20 }} />
          <Button title='Tambah Lagi' onPress={() => this.back()} />
        </View>
      </View>
    );

  }
}

export default Page;
