import React from 'react';
import feathersLib from '../feathersLib';
import Lib from '../Lib';
import { View, TextInput, Text, TouchableHighlight } from 'react-native';
import Button from './Button';
import HeaderBar from './HeaderBar';

const { PRIMARY_COLOR, TEXT_GREY_COLOR } = require('./Data');

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: 'busy'
    };
  }

  async componentDidMount() {
  }

  componentWillMount() {
  }

  async next() {
    const phoneNum = this.state.phoneNum;
    await feathersLib.createPhoneOTP(phoneNum);
    this.props.history.push('/login-step-2/' + phoneNum);
  }

  async nextByPassword() {
    const phoneNum = this.state.phoneNum;
    const password = this.state.password;
    try {
      const result = await feathersLib.verifyPhonePassword(phoneNum, password);
      const token = result.token;
      await Lib.setStorageValue('GREENS_TOKEN', token);
      this.props.history.push('/home');
    } catch (err) {
      console.error(err);
    }
  }

  back() {
    this.props.history.goBack();
  }

  renderPassword() {
    const inputStyle = { height: 40, padding: 5, outlineWidth: 0 };
    const borderWidth = 2;
    return (
      <View style={{ flex: 1 }}>
        <HeaderBar goBack={() => this.back()} />
        <View style={{ flex: 1, padding: 32 }}>
          <Text style={{ color: TEXT_GREY_COLOR, fontSize: 20, fontWeight: 700 }}>Login menggunakan password:</Text>
          <Text> </Text>
          <View style={{ borderWidth: borderWidth, borderRadius: 8, borderColor: PRIMARY_COLOR }}>
            <View style={{ padding: 5 }}>
              <TextInput
                style={inputStyle}
                onChangeText={txt => this.setState({ phoneNum: txt })}
                value={this.state.phoneNum}
                placeholderTextColor={PRIMARY_COLOR}
                keyboardType='phone-pad'
                placeholder="No. Handphone" />
            </View>
            <View style={{ height: borderWidth, backgroundColor: PRIMARY_COLOR }} />
            <View style={{ padding: 5 }}>
              <TextInput
                style={inputStyle}
                onChangeText={txt => { this.setState({ password: txt }); }}
                value={this.state.password}
                placeholderTextColor={PRIMARY_COLOR}
                secureTextEntry={true}
                placeholder="Password" />
            </View>
          </View>
          <Text> </Text>
          <Text style={{ color: PRIMARY_COLOR }}>
            Login dengan OTP?&nbsp;
            <TouchableHighlight onPress={() => this.setState({ usePassword: false })}>
              <Text style={{ color: PRIMARY_COLOR, fontWeight: 'bold', textDecoration: 'underline' }}>Klik disini</Text>
            </TouchableHighlight>
          </Text>
        </View>
        <View style={{ paddingVertical: 32, paddingHorizontal: 64 }}>
          <Button title='Lanjutkan' onPress={() => this.nextByPassword()} />
        </View>
      </View>
    );
  }

  render() {
    if (this.state.usePassword) return this.renderPassword();

    const inputStyle = { height: 40, padding: 5, outlineWidth: 0 };
    const borderWidth = 2;
    return (
      <View style={{ flex: 1 }}>
        <HeaderBar goBack={() => this.back()} />
        <View style={{ flex: 1, padding: 32 }}>
          <Text style={{ color: TEXT_GREY_COLOR, fontSize: 20, fontWeight: 700 }}>Kami akan mengirimkan 6 digit kode OTP pada No berikut:</Text>
          <Text> </Text>
          <View style={{ borderWidth: borderWidth, borderRadius: 8, borderColor: PRIMARY_COLOR }}>
            <View style={{ padding: 5 }}>
              <TextInput
                style={inputStyle}
                onChangeText={txt => this.setState({ phoneNum: txt })}
                value={this.state.phoneNum}
                placeholderTextColor={PRIMARY_COLOR}
                keyboardType='phone-pad'
                placeholder="No. Handphone" />
            </View>
          </View>
          <Text> </Text>
          <Text style={{ color: PRIMARY_COLOR }}>
            Login dengan password?&nbsp;
            <TouchableHighlight onPress={() => this.setState({ usePassword: true })}>
              <Text style={{ color: PRIMARY_COLOR, fontWeight: 'bold', textDecoration: 'underline' }}>Klik disini</Text>
            </TouchableHighlight>
          </Text>
        </View>
        <View style={{ paddingVertical: 32, paddingHorizontal: 64 }}>
          <Button title='Lanjutkan' onPress={() => this.next()} />
        </View>
      </View>
    );

  }
}

export default Page;
