import React from 'react';
import { View, Text, ScrollView } from 'react-native';
import HeaderBar from './HeaderBar';

const { PRIMARY_COLOR } = require('./Data');

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: 'busy'
    };
  }

  async componentDidMount() {
  }

  componentWillMount() {
  }

  submit() {

  }

  back() {
    this.props.history.goBack();
  }

  render() {


    return (
      <View style={{ flex: 1, backgroundColor: PRIMARY_COLOR }}>
        <HeaderBar type='green' title='Kebijakan Privasi' goBack={() => this.back()} />
        <View style={{ flex: 1 }}>
          <ScrollView style={{ flex: 1, paddingHorizontal: 32, paddingVertical: 32 }}>
            <Text style={{ color: 'white' }}>
              <h3 style={{ color: 'white' }}>A. Pengantar</h3>
              <p>
                Kebijakan Privasi ini berlaku untuk situs web dan/atau aplikasi GREENS yang menghubungkan Pengguna dengan GREENS dalam menggunakan informasi dan jasa Layanan yang tersedia. Kebijakan ini bertujuan untuk membantu Pengguna memahami informasi mengenai kebijakan pengumpulan, penggunaan, penyimpanan dan penghapusan informasi apabila Pengguna menggunakan situs web dan/atau aplikasi GREENS.
              </p><p>
                Dengan memilih menggunakan situs web dan/atau aplikasi GREENS, Pengguna menyetujui pengumpulan, penggunaan, penyimpanan dan penghapusan informasi sehubungan dengan Kebijakan Privasi ini. Seluruh informasi yang diberikan Pengguna hanya akan digunakan GREENS sebagaimana dijelaskan dalam Kebijakan Privasi ini. Istilah yang digunakan dalam Kebijakan Privasi ini memiliki arti yang sama dan merupakan satu kesatuan dengan Syarat Dan Ketentuan GREENS kecuali ditentukan lain dalam Kebijakan Privasi ini.
              </p>
              <h3 style={{ color: 'white' }}>B. Pengumpulan Informasi</h3>
              <p>
                Pada saat Pengguna menggunakan situs web dan/atau aplikasi dan/atau mengunjungi outlet GREENS, GREENS akan/mungkin mengumpulkan informasi yang diberikan Pengguna termasuk namun tidak terbatas pada nama lengkap Pengguna, nomor telepon, data transaksi seperti jenis, jumlah dan/atau keterangan produk lainnya, frekuensi partisipasi promosi, postingan komentar dan pertanyaan detil informasi pembayaran dan informasi tambahan yang diminta untuk menunjukkan keaslian identitas Pengguna.
              </p><p>
                Situs web dan/atau aplikasi GREENS menggunakan layanan pihak ketiga yang dapat mengumpulkan informasi yang digunakan untuk mengidentifikasi Pengguna seperti pihak payment gateway, pihak server dan pihak-pihak terkait lainnya.
              </p><p>
                GREENS menganggap bahwa informasi yang Pengguna berikan saat ini dan perubahan-perubahan yang Pengguna lakukan sekarang ataupun di masa yang akan datang adalah benar dan akurat. Apabila informasi dan perubahan-perubahan yang diberikan ternyata terbukti tidak benar, maka GREENS tidak bertanggung jawab atas segala akibat yang dapat terjadi sehubungan dengan pemberian informasi dan perubahan-perubahan yang tidak benar tersebut.
              </p>
              <h3 style={{ color: 'white' }}>C. Penggunaan Informasi</h3>
              <p>
                Semua informasi yang diperoleh dari pemberian Pengguna akan disimpan, digunakan, dilindungi dan dihapus sesuai dengan peraturan perlindungan data yang berlaku dan Kebijakan Privasi ini.
              </p><p>
                Informasi Pengguna akan digunakan yang mencakup namun tidak terbatas pada memproses segala bentuk permintaan, aktivitas maupun  transaksi yang dilakukan Pengguna melalui situs web dan/atau aplikasi GREENS, kepentingan pemasaran dalam komunikasi dengan Pengguna, kepentingan riset pasar dan/atau pengembangan situs web dan/atau aplikasi, pengembangan produk dan layanan, monitoring ataupun investigasi terhadap transaksi-transaksi atau aktivitas-aktivitas mencurigkan atau yang terindikasi mengandung unsur kecurigaan atau pelanggaran terhadap Syarat Dan Ketentuan atau pelanggaran terhadap hukum yang berlaku.
              </p><p>
                GREENS tidak akan menjual, mendistribusikan atau menyewa informasi Pelanggan kepada pihak lain, kecuali terbatas pada hal-hal seperti
                <ol>
                  <li>Diwajibkan dan/atau diminta oleh institusi berwenang berdasarkan ketentuan hukum yang berlaku seperti Pengadilan, instansi atau aparat berwenang untuk keperluan termasuk namun tidak terbatas pada perselisihan, penyelidikan, proses hukum, proses penyelesaian sengketa dan kegiatan illegal lainnya.</li>
                  <li>Diperlukan oleh subsidiari, afiliasi, mitra dan/atau pihak ketiga lain yang terkait dalam rangka melaksanakan pekerjaannya seperti pemenuhan pesanan, pengiriman/pengambilan pesanan, proses pembayaran transaksi, media komunikasi, analisa data, layanan bantuan, peningkatan sistem keamanan dan privasi, peningkatan dan pengembangan situs web dan/atau aplikasi.</li>
                  <li>Diwajibkan oleh instansi perbankan dan/atau keuangan yang berwenang dan/atau pihak ketiga berdasarkan permintaan resmi dan sesuai dengan ketentuan hukum yang berlaku.</li>
                  <li>Apabila terjadi pengalihan unit aset atau kepemilikan usaha kepada pihak lain.</li>
                </ol>
              </p><p>
                Selain dari yang disebutkan diatas, Pengguna akan menerima pemberitahuan apabila akan terjadi pemberian informasi pribadi kepada pihak lain. GREENS akan mengungkapkan informasi Pengguna dalam upaya mematuhi kewajiban hukum dan/atau adanya permintaan yang sah dari aparat penegak hukum.
              </p>
              <h3 style={{ color: 'white' }}>D. Koneksi Situs Lain</h3>
              <p>
                Penggunaan situs web dan/atau aplikasi GREENS mungkin akan berisi dan/atau menyediakan tautan ke situs-situs pihak lain. Penggunaan situs-situs pihak lain tersebut bersifat eksternal, tidak dimiliki dan dioperasi oleh GREENS. Oleh karena itu, GREENS sangat menghimbau Pengguna untuk membaca kebijakan privasi situs terkait sebelum Pengguna menggunakan situs tersebut. GREENS tidak memiliki kendali atas situs-situs tersebut dan tidak bertanggung jawab atas konten, kebijakan privasi dan/atau praktik situs atau layanan pihak ketiga manapun.
              </p>
              <h3 style={{ color: 'white' }}>E. Keamanan</h3>
              <p>
                GREENS berkomitmen untuk melindungi informasi Pengguna dalam keadaan aman dengan menggunakan langkah-langkah keamanan teknis, pengamanan elektronik maupun fisik dan prosedur administratif untuk mengurangi resiko kehilangan, penyalahgunaan, akses tidak sah, pengungkapan atau modifikasi informasi Pengguna. GREENS akan menggunakan teknologi dan proses keamanan yang layak dan memadai dalam membantu menjaga keamanan informasi Pengguna. Namun tidak ada metode transmisi melalui internet atau metode penyimpanan elektronik yang sepenuhnya aman, oleh karenanya GREENS tidak dapat menjamin keamanan mutlaknya.
              </p>
              <h3 style={{ color: 'white' }}>F. Kepatuhan dan Perubahan Kebijakan Privasi</h3>
              <p>
                GREENS secara teratur meninjau Kebijakan Privasi ini dan memastikan bahwa GREENS memproses informasi Pengguna sesuai dengan kebijakan tersebut. GREENS berhak memperbarui Kebijakan Privasi dari waktu ke waktu dan perubahan akan berlaku segera setelah ditaut pada halaman Kebijakan Privasi. Pengguna disarankan untuk meninjau Kebijakan Privasi secara berkala.
              </p>
              <h3 style={{ color: 'white' }}>G. Penyimpanan dan Penghapusan Informasi</h3>
              <p>
                GREENS berhak menyimpan informasi Pengguna baik didalam wilayah Republik Indonesia maupun diluar wilayah Republik Indonesia dan GREENS juga berhak untuk melakukan penghapusan sesuai dengan ketentuan hukum yang berlaku.
              </p>
              <h3 style={{ color: 'white' }}>H. Cookie</h3>
              <p>
                Cookie adalah file kecil dari perangkat yang secara otomatis menyimpan informasi tentang preferensi Pengguna. Situs web dan/atau aplikasi GREENS mungkin menggunakan cookie untuk mengumpulkan atau memberikan informasi yang dapat membantu memperbaiki platform dan Layanan yang ditawarkan. Pengguna memiliki pilihan untuk menerima atau menolak cookie tersebut dari perangkat Pengguna. Apabila Pengguna memilih untuk menolak cookie GREENS, Pengguna mungkin tidak dapat mendapatkan layanan yang optimal pada platform GREENS.
              </p>
              <h3 style={{ color: 'white' }}>I. Pembatasan Tanggung Jawab</h3>
              <p>
                GREENS dalam pengumpulan dan pengolahan informasi Pengguna, termasuk transmisi data sesuai dengan tujuan Kebijakan Privasi ini akan menerapkan keamanan sewajarnya. Pembatasan tanggung jawab GREENS adalah sebagai berikut:
                <ol>
                  <li>GREENS tidak bertanggung jawab atas pertukaran dan pemberian data dan informasi pribadi Pengguna yang dilakukan oleh Pengguna sendiri. Termasuk dalam ketentuan ini adalah setiap dan segala kesalahan pengguna yang mengakibatkan kebocoran informasi data Pengguna.</li>
                  <li>GREENS tidak bertanggung jawab atas keaslian, kebenaran, keakuratan dan kelengkapan data yang diberikan oleh Pengguna kedalam sistem GREENS.</li>
                  <li>Dengan mengakses dan memberikan informasi, Pengguna menyetujui bahwa Pengguna melepaskan hak atas klaim, kerugian, tuntutan dan gugatan yang mungkin terjadi atas pengumpulan, penggunaan, penyimpanan dan penghapusan informasi dari sistem GREENS.</li>
                  <li>Situs web dan/atau aplikasi GREENS tidak dimaksudkan untuk digunakan oleh siapa pun yang berusia dibawah umur menurut undang-undang dan peraturan yang berlaku, kecuali dalam pengawasan orangtua/wali. GREENS tidak bertanggung jawab atas input data Pengguna anak dan menganjurkan agar orangtua/wali anak memantau penggunaan perangkat dan/atau internet anak, sehingga pemberian informasi Pengguna anak diberikan dan/atau dalam pengawasan orangtua/ wali sebagai pihak berwenang.</li>
                  <li>GREENS tidak bertanggung jawab atas kebocoran data yang terjadi dikarenakan dan/atau terjadi selama  Memaksa dan/atau Keadaan Kahar (Force Majeure). Keadaan Memaksa dan/atau Keadaan Kahar dalam hal ini meliputi tapi tidak terbatas pada pemogokan, penutupan perusahaan; huru-hara, kerusuhan, serangan atau ancaman teroris, ancaman atau perang; kebakaran, ledakan, bencana alam atau bencana non alam, epidemi atau pandemi; tidak adanya atau terganggunya jaringan telekomunikasi, informatika, listrik; terjadinya kegagalan sistem yang diakibatkan pihak ketiga di luar kewenangan GREENS; terjadinya kegagalan atau tidak berfungsinya sistem dan/atau jaringan perbankan; ketentuan perundang-undangan, peraturan dari pemerintah, putusan pengadilan. Dalam hal terjadi Keadaan Memaksa dan/atau Keadaan Kahar, GREENS akan memberitahukan Pengguna paling lambat 14 (empat belas) hari kalender sejak kejadian keadaan memaksa dan berusaha dengan kemampuan terbaiknya memenuhi kewajiban berdasarkan Kebijakan Privasi ini.</li>
                </ol>
              </p>

            </Text>
          </ScrollView>
        </View>
      </View>
    );

  }
}

export default Page;
