import React from 'react';
// import moment from 'moment';
// import feathersLib from './feathersLib';
// import { Select, Form, Input, Button, Space, InputNumber, Card, Table } from 'antd';
// import { Layout } from 'antd';
// import { Row, Col } from 'antd';
// import { Typography } from 'antd';
// import Lib from './Lib';
// import Swal from 'sweetalert2';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import "antd/dist/antd.css";
// import Admin from './admin/Admin';
import AppRouter from './app/AppRouter';

// const { Title } = Typography;
// const { Option } = Select;
// const { Header, Footer, Content } = Layout;

// const LOGO = require('./img/logo.png');

// const FormSale = (props) => {
//   const onFinishFailed = (errorInfo) => {
//     console.error('Failed:', errorInfo);
//   };

//   const nextMon1 = moment().day(1 + 7).format();
//   const nextMon2 = moment().day(1 + 7 + 7).format();
//   const nextMon3 = moment().day(1 + 7 + 7 + 7).format();
//   const nextMonLabel1 = moment().day(1 + 7).format('DD MMMM YYYY');
//   const nextMonLabel2 = moment().day(1 + 7 + 7).format('DD MMMM YYYY');
//   const nextMonLabel3 = moment().day(1 + 7 + 7 + 7).format('DD MMMM YYYY');

//   return (
//     <Form
//       labelCol={{
//         xs: { span: 24 }, sm: { span: 24 }, md: { span: 8 }
//       }}
//       wrapperCol={{
//         xs: { span: 24 }, sm: { span: 24 }, md: { span: 16 }
//       }}
//       layout="default"
//       initialValues={{ amount: 1, duration: "5", startDate: nextMon1 }}
//       onFinish={props.onSubmit}
//       onFinishFailed={onFinishFailed}
//     >
//       <Form.Item
//         label="Nama"
//         name="name"
//         rules={[{ required: true, message: 'Silahkan masukan nama!' }]}
//       >
//         <Input />
//       </Form.Item>

//       <Form.Item
//         label="No. Telpon"
//         name="phoneNum"
//         rules={[{ required: true, message: 'Silahkan masukan nomor telpon!' }]}
//       >
//         <Input />
//       </Form.Item>

//       <Form.Item
//         label="Alamat"
//         name="address"
//         rules={[{ required: true, message: 'Silahkan masukan alamat pengiriman!' }]}
//       >
//         <Input.TextArea />
//       </Form.Item>

//       <Form.Item
//         label="Jumlah Pesanan"
//         name="amount"
//         rules={[{ required: true, message: 'Silahkan masukan jumlah pesanan!' }]}
//       >
//         <InputNumber min={1} max={10} defaultValue={1} />
//       </Form.Item>

//       <Form.Item
//         label="Durasi Katering"
//         name="duration"
//         rules={[{ required: true, message: 'Silahkan masukan durasi katering!' }]}
//       >
//         <Select>
//           <Option value="5">5 Hari (Senin sd Jumat)</Option>
//           <Option value="20">20 Hari (Senin sd Jumat)</Option>
//         </Select>
//       </Form.Item>

//       <Form.Item
//         label="Tanggal Dimulai"
//         name="startDate"
//         rules={[{ required: true, message: 'Silahkan masukan tanggal dimulai!' }]}
//       >
//         <Select>
//           <Option value={nextMon1}>Senin minggu depan ({nextMonLabel1})</Option>
//           <Option value={nextMon2}>Senin 2 minggu lagi ({nextMonLabel2})</Option>
//           <Option value={nextMon3}>Senin 3 minggu lagi ({nextMonLabel3})</Option>
//         </Select>
//       </Form.Item>


//       <Form.Item wrapperCol={{ xs: { span: 24 }, sm: { span: 24 }, md: { offset: 8, span: 16 } }} >
//         <Space>
//           <Button type="primary" htmlType="submit">
//             Submit
//           </Button>
//           <Button onClick={() => props.onCancel()}>
//             Cancel
//           </Button>
//         </Space>
//       </Form.Item>
//     </Form >
//   );
// };

// class App extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       show: 'busy'
//     };
//   }

//   async componentDidMount() {
//     //  ,,await Lib.clearStorageValue();
//     // await this.showInvoice();
//     try {
//       let password = await Lib.getStorageValue('GREENS_ORDER_TMP');
//       let email;
//       if (!password || password.length === 0) {
//         password = 'guest' + moment().valueOf();
//         email = password + '@email.com';
//         await feathersLib.register(email, password);
//         await Lib.setStorageValue('GREENS_ORDER_TMP', password);
//       } else {
//         email = password + '@email.com';
//       }
//       await feathersLib.loginByEmailPassword(email, password);

//       await this.showInvoice();
//     } catch (err) {
//       await Lib.clearStorageValue();
//     }
//   }

//   componentWillMount() {
//   }

//   async showInvoice() {
//     const invs1 = await feathersLib.getInvoicesPaid();
//     const invs2 = await feathersLib.getInvoicesNotPaid();
//     let invoicePaid = false;
//     let invoiceNotPaid = false;
//     if (invs1.total > 0) invoicePaid = invs1.data[0];
//     if (invs2.total > 0) invoiceNotPaid = invs2.data[0];
//     this.setState({ show: 'invoice', invoicePaid: invoicePaid, invoiceNotPaid: invoiceNotPaid, invoicePaids: invs1.data, invoiceNotPaids: invs2.data });
//   }

//   async submitOrder(data) {
//     try {
//       // name*
//       // phoneNum*
//       // address*
//       // duration*
//       // startDate*
//       // amount*

//       const invoice = await feathersLib.createInvoice({
//         name: data.name,
//         phoneNum: data.phoneNum,
//         address: data.address,
//         duration: data.duration,
//         startDate: data.startDate,
//         amount: data.amount
//       });
//       console.log({ invoice });

//       await Lib.setStorageValue('SAVED_NUMBER', data.phoneNum);
//       this.showInvoice();

//       Swal.fire({
//         icon: 'success',
//         html: 'Pesanan dibuat. Silahkan melanjutkan ke pembayaran.'
//       });

//     } catch (err) {
//       console.error(err);

//       Swal.fire({
//         icon: 'error',
//         html: 'Pesanan gagal dibuat. Cobalah beberapa saat lagi.'
//       });
//     }
//   }

//   async confirmPayment() {
//     try {
//       const id = this.state.invoiceNotPaid._id;
//       const invoice = await feathersLib.confirmInvoice(id);

//       this.showInvoice();

//       Swal.fire({
//         icon: 'success',
//         html: 'Konfirmasi sukses'
//       });
//     } catch (err) {
//       console.error(err);
//       Swal.fire({
//         icon: 'error',
//         html: 'Konfirmasi gagal'
//       });
//     }
//   }

//   async removePayment() {
//     try {
//       const id = this.state.invoiceNotPaid._id;
//       const invoice = await feathersLib.removeInvoice(id);
//       this.showInvoice();
//       Swal.fire({
//         icon: 'success',
//         html: 'Hapus sukses'
//       });
//     } catch (err) {
//       console.error(err);
//       Swal.fire({
//         icon: 'error',
//         html: 'Hapus gagal'
//       });
//     }
//   }


//   redirectTo(url) {
//     Lib.redirectToUrl(url);
//   }

//   renderTablePending() {
//     const dataSource = this.state.invoiceNotPaids ? this.state.invoiceNotPaids : [];

//     // const startDate = moment(invoiceNotPaid.startDate).format('DD MMMM YYYY');
//     // let endDate = moment(invoiceNotPaid.startDate).add(5, 'days').format('DD MMMM YYYY');
//     // if (Number(invoiceNotPaid.duration) === 20) endDate = moment(invoiceNotPaid.startDate).add(21 + 5, 'days').format('DD MMMM YYYY');
//     // const url = invoiceNotPaid.snapUrl;
//     // const createdAt = moment(invoiceNotPaid.createdAt).format('DD MMMM YYYY HH:mm:ss');
//     // pendingPayment = (
//     //   <Col span={24}>
//     //     <Row gutter={[8, 8]}>
//     //       <Col span={24}>
//     //         <div>PESANAN MENUNGGU PEMBAYARAN</div>
//     //         <div>{createdAt}</div><br />
//     //         <p>
//     //           Tanggal: {createdAt}<br />
//     //           Nama: {invoiceNotPaid.name}<br />
//     //           No. Telpon: {invoiceNotPaid.phoneNum}<br />
//     //           Alamat: {invoiceNotPaid.address}<br />
//     //           Paket: {invoiceNotPaid.duration} hari<br />
//     //           Periode: {startDate} s/d {endDate}
//     //         </p>


//     const columns = [
//       {
//         title: 'Pending Payment',
//         key: '_id',
//         render: (text, record) => {
//           const name = record.name;
//           const phoneNum = record.phoneNum;
//           const address = record.address;

//           return (
//             <Space direction="vertical">
//               {name}
//               {'Phone: ' + phoneNum}
//               {'Address: ' + address}
//             </Space>
//           );
//         },
//       }
//     ];

//     return (
//       <Table
//         dataSource={dataSource}
//         columns={columns}
//       />

//     );
//   }

//   renderInvoice() {
//     const invoiceNotPaid = this.state.invoiceNotPaid;
//     const notPaidData = JSON.stringify(invoiceNotPaid, null, 2);

//     const invoicePaid = this.state.invoicePaid;
//     const paidData = JSON.stringify(invoicePaid, null, 2);

//     let pendingPayment = null;
//     let successPayment = null;

//     if (invoiceNotPaid) {
//       const startDate = moment(invoiceNotPaid.startDate).format('DD MMMM YYYY');
//       let endDate = moment(invoiceNotPaid.startDate).add(5, 'days').format('DD MMMM YYYY');
//       if (Number(invoiceNotPaid.duration) === 20) endDate = moment(invoiceNotPaid.startDate).add(21 + 5, 'days').format('DD MMMM YYYY');
//       const url = invoiceNotPaid.snapUrl;
//       const createdAt = moment(invoiceNotPaid.createdAt).format('DD MMMM YYYY HH:mm:ss');
//       pendingPayment = (
//         <Col span={24}>
//           <Row gutter={[8, 8]}>
//             <Col span={24}>
//               <div>PESANAN MENUNGGU PEMBAYARAN</div>
//               <div>{createdAt}</div><br />
//               <p>
//                 Tanggal: {createdAt}<br />
//                 Nama: {invoiceNotPaid.name}<br />
//                 No. Telpon: {invoiceNotPaid.phoneNum}<br />
//                 Alamat: {invoiceNotPaid.address}<br />
//                 Paket: {invoiceNotPaid.duration} hari<br />
//                 Periode: {startDate} s/d {endDate}
//               </p>
//             </Col>
//             <Col span={24}>
//               <Row gutter={[8, 8]}>
//                 <Col xs={24} sm={8}>
//                   <Button block type="primary" onClick={() => this.redirectTo(url)}>Pembayaran</Button>
//                 </Col>
//                 <Col xs={24} sm={8}>
//                   <Button block type="primary" onClick={() => this.confirmPayment()}>Konfirmasi</Button>
//                 </Col>
//                 <Col xs={24} sm={8}>
//                   <Button block type="primary" onClick={() => this.removePayment()}>Hapus</Button>
//                 </Col>
//               </Row>
//             </Col>
//           </Row>
//         </Col>
//       );
//     }

//     if (invoicePaid) {
//       const startDate = moment(invoicePaid.startDate).format('DD MMM YYYY');
//       let endDate = moment(invoicePaid.startDate).add(5, 'days').format('DD MMM YYYY');
//       if (Number(invoicePaid.duration) === 20) endDate = moment(invoicePaid.startDate).add(21 + 5, 'days').format('DD MMM YYYY');
//       const createdAt = moment(invoiceNotPaid.createdAt).format('DD MMMM YYYY HH:mm:ss');
//       successPayment = (
//         <Col span={24}>
//           <div>PESANAN AKTIF</div>
//           <div>{createdAt}</div><br />
//           <p>
//             Nama: {invoicePaid.name}<br />
//             No. Telpon: {invoicePaid.phoneNum}<br />
//             Alamat: {invoicePaid.address}<br />
//             Paket: {invoicePaid.duration} hari<br />
//             Periode: {startDate} s/d {endDate}
//           </p>
//         </Col>
//       );
//     }

//     let noPayment = null;

//     if (!invoicePaid && !invoiceNotPaid) noPayment = (<Col span={24}>Belum Ada Pesanan nih</Col>);

//     return (
//       <Row gutter={[8, 8]}>
//         {noPayment}
//         {successPayment}
//         {pendingPayment}
//         <Col span={24}>
//           {this.renderTablePending()}
//         </Col>
//         <Col span={24}>
//           <Button block type="primary" onClick={() => this.setState({ show: 'form' })}>Pesanan baru</Button>
//         </Col>
//       </Row>
//     );
//   }

//   render() {

//     let content;

//     if (this.state.show === 'form') content = (
//       <Row>
//         <Col sm={24} xs={24} style={{}}>
//           <Title level={2} style={{ textAlign: 'center' }}>Form Order</Title>
//           <FormSale onSubmit={data => this.submitOrder(data)} onCancel={() => this.showInvoice()} />
//         </Col>
//       </Row>
//     );
//     else if (this.state.show === 'invoice') content = this.renderInvoice();

//     return (
//       <Layout>
//         <Header style={{ backgroundColor: 'white' }}>
//           <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
//             <img src={LOGO} />
//           </Space>
//         </Header>
//         <Content style={{ padding: 50 }}>
//           <Row align='middle' justify='center'>
//             <Col xs={24} sm={24} md={12} lg={12} xl={12}>
//               <Card>

//                 {content}
//               </Card>
//             </Col>
//           </Row>
//         </Content>
//         <Footer>
//           <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
//             Copyright 2021 by GREENS.ID
//           </Space>
//         </Footer>
//       </Layout>
//     );

//   }
// }

function GlobalRouter() {
  return (
    <Router>
      <Switch>
        <Route path="/">
          <AppRouter />
        </Route>
      </Switch>
    </Router>
  );
}

export default GlobalRouter;
