import React from 'react';
import feathersLib from '../feathersLib';
import Lib from '../Lib';
import cartLib from '../cartLib';
import { TextInput, View, Text, ScrollView } from 'react-native';
import Button from './Button';
import HeaderBar from './HeaderBar';

const { PRIMARY_COLOR } = require('./Data');

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: 'busy',
      password1: '',
      password2: ''
    };
  }

  async componentDidMount() {
    // const o = await cartLib.loadOrderData();

    // const original = JSON.stringify({
    //   name: o.name,
    //   phoneNum: o.phoneNum,
    //   address: o.address,
    //   notes: o.notes
    // });

    // this.setState({
    //   original,
    //   name: o.name,
    //   phoneNum: o.phoneNum,
    //   address: o.address,
    //   notes: o.notes
    // });

    const token = await Lib.getStorageValue('GREENS_TOKEN');
    try {
      if (token && token.length > 0) {
        await feathersLib.loginByAccessToken(token);
        console.log(feathersLib.user);
        const user = feathersLib.user;
        const name = user.name;
        const phoneNum = user.phoneNum;
        const address = user.address;
        const notes = user.notes;
        const secret = user.secret;

        const original = JSON.stringify({
          name: name,
          phoneNum: phoneNum,
          address: address,
          notes: notes
        });

        this.setState({
          original,
          fixedPhoneNum: true,
          name: name,
          phoneNum: phoneNum,
          address: address,
          notes: notes,
          password1: secret ? secret : '',
          password2: secret ? secret : ''
        });

      }

    } catch (err) {
      console.error(err);
    }
  }

  componentWillMount() {
  }

  async save() {
    const name = this.state.name;
    const phoneNum = this.state.phoneNum;
    const address = this.state.address;
    const notes = this.state.notes;
    const data = {
      name,
      phoneNum,
      address,
      notes
    }
    await cartLib.saveOrderData(data);
    this.setState({ original: JSON.stringify(data) });

    let secret = '';
    if (this.state.password1 && this.state.password1.length > 3) {
      secret = this.state.password1;
    }

    const id = feathersLib.user._id;
    const user = await feathersLib.patchUser(id, { name, address, notes, secret });
    console.log(user);
  }

  back() {
    this.props.history.goBack();
  }

  render() {

    const inputStyle = { height: 40, padding: 5, outlineWidth: 0 };
    const inputStyle2 = { height: 80, padding: 5, outlineWidth: 0 };
    const borderWidth = 2;

    const name = this.state.name;
    const phoneNum = this.state.phoneNum;
    const address = this.state.address;
    const notes = this.state.notes;

    const check = JSON.stringify({
      name,
      phoneNum,
      address,
      notes
    });

    const needToSave = (this.state.original !== check);

    let enableNext = (name && phoneNum && address && name.length > 0 && phoneNum.length > 0 && address.length > 0 && needToSave);

    if (this.state.password1.length > 0) {
      const passwordOK = (this.state.password1 === this.state.password2 && this.state.password1.length >= 3);
      if (!passwordOK) enableNext = false;
    }

    return (
      <View style={{ flex: 1 }}>
        <HeaderBar title={'Profil Saya'} goBack={() => this.back()} />
        <View style={{ flex: 1 }}>
          <ScrollView style={{ flex: 1, padding: 32 }}>
            <Text style={{ color: PRIMARY_COLOR, fontSize: 20, fontWeight: 700 }}>Data Pemesan</Text>
            <Text> </Text>
            <View style={{ borderWidth: borderWidth, borderRadius: 8, borderColor: PRIMARY_COLOR, paddingBottom: 16 }}>
              <View style={{ padding: 5 }}>
                <TextInput
                  style={inputStyle}
                  onChangeText={txt => this.setState({ name: txt })}
                  value={this.state.name}
                  placeholderTextColor={PRIMARY_COLOR}
                  placeholder="Nama Pemesan" />
              </View>
              <View style={{ height: 1, backgroundColor: PRIMARY_COLOR }} />
              <View style={{ padding: 5 }}>
                <TextInput
                  disabled={(this.state.fixedPhoneNum)}
                  style={inputStyle}
                  onChangeText={txt => this.setState({ phoneNum: txt })}
                  value={this.state.phoneNum}
                  placeholderTextColor={PRIMARY_COLOR}
                  keyboardType='phone-pad'
                  placeholder="No. Handphone Pemesan" />
              </View>
              <View style={{ height: 1, backgroundColor: PRIMARY_COLOR }} />
              <View style={{ padding: 5 }}>
                <TextInput
                  style={inputStyle2}
                  onChangeText={txt => this.setState({ address: txt })}
                  value={this.state.address}
                  multiline={true}
                  placeholderTextColor={PRIMARY_COLOR}
                  placeholder="Alamat Tujuan" />
              </View>
              <View style={{ height: 1, backgroundColor: PRIMARY_COLOR }} />
              <View style={{ padding: 5 }}>
                <TextInput
                  style={inputStyle2}
                  onChangeText={txt => this.setState({ notes: txt })}
                  value={this.state.notes}
                  multiline={true}
                  placeholderTextColor={PRIMARY_COLOR}
                  placeholder="Catatan (alergi, dll)" />
              </View>
            </View>
            <Text> </Text>
            <View style={{ borderWidth: borderWidth, borderRadius: 8, borderColor: PRIMARY_COLOR, paddingBottom: 8 }}>
              <View style={{ padding: 5 }}>
                <TextInput
                  style={inputStyle}
                  onChangeText={txt => this.setState({ password1: txt })}
                  value={this.state.password1}
                  secureTextEntry={true}
                  placeholderTextColor={PRIMARY_COLOR}
                  placeholder="Password" />
              </View>
              <View style={{ height: 1, backgroundColor: PRIMARY_COLOR }} />
              <View style={{ padding: 5 }}>
                <TextInput
                  style={inputStyle}
                  onChangeText={txt => this.setState({ password2: txt })}
                  value={this.state.password2}
                  secureTextEntry={true}
                  placeholderTextColor={PRIMARY_COLOR}
                  placeholder="Ulangi password" />
              </View>
            </View>
          </ScrollView>
        </View>
        <View style={{ paddingVertical: 32, paddingHorizontal: 64 }}>
          <Button disabled={!enableNext} title='Simpan' onPress={() => this.save()} />
        </View>
      </View>
    );

  }
}

export default Page;
