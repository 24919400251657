import React from 'react';
// import moment from 'moment';
import feathersLib from '../feathersLib';
import Lib from '../Lib';
import cartLib from '../cartLib';
// import Swal from 'sweetalert2';
import { TouchableHighlight, View, ImageBackground, Text, ScrollView, Image } from 'react-native';
import Button from './Button';
import HeaderBar from './HeaderBar';
import SetNumber from './SetNumber';
import Modal from 'modal-enhanced-react-native-web';

const { PRIMARY_COLOR } = require('./Data');

const BG3_IMG = require('../img/bg3.png');
const CLOSE = require('../img/close.png');
const SLIDE1 = require('../img/slide1.png');
const MG00 = require('../img/mg00.png');

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: 'busy',
      modalIntroVisible: false,
      isLogin: false,
      numCoin: false,
      cartData: {
        items: {},
        numItems: 0,
        total: 0
      },
      saleItems: [],
      selectedItemAmount: 0
    };
  }

  async componentDidMount() {
    const { width, height } = Lib.getScreenSize();
    this.setState({ sw: width, sh: height });

    try {
      let saleItems = await feathersLib.findSaleItems();
      saleItems = saleItems.data;
      // console.log({ saleItems });

      let cartData = await cartLib.loadData();

      this.setState({ saleItems, cartData });
    } catch (err) {

    }

    const token = await Lib.getStorageValue('GREENS_TOKEN');

    try {
      if (token && token.length > 0) {
        // console.log('token: ' + token);
        await feathersLib.loginByAccessToken(token);
        // if login get num points
        console.log(feathersLib.user);
        let numCoin = 0;
        if (feathersLib.user && feathersLib.user.points && feathersLib.user.points > 0) numCoin = feathersLib.user.points;

        this.setState({ show: 'home', isLogin: true, numCoin });
      } else {
        throw new Error('token fail');;
      }
    } catch (err) {
      console.error('login error');
      console.error(err);
      this.setState({ show: 'login' });
    }
  }

  componentWillMount() {
  }

  showCart() {
    this.props.history.push('/cart');
  }

  loginNow() {
    this.props.history.push('/login-step-1');
  }

  async logout() {
    // TODO
    await feathersLib.logOut();
    await cartLib.resetData();
    await cartLib.resetOrderData();
    window.location.reload();
  }

  showMenu() {

  }

  orderHistory() {
    this.props.history.push('/order-history');
  }

  clickMenu(item) {
    const items = this.state.cartData && this.state.cartData.items ? this.state.cartData.items : {};
    let amount = items[item._id] && items[item._id].amount ? items[item._id].amount : 0;
    this.setState({ modalDetailVisible: true, selectedItem: item, selectedItemAmount: amount });
  }

  async addToCart(item) {
    const amount = this.state.selectedItemAmount;
    // console.log('selected amount ' + amount);
    const cartData = await cartLib.setItemAmount(item, amount);
    this.setState({ cartData: cartData, modalDetailVisible: false });
  }

  renderModalIntro() {
    const sw = this.state.sw * 0.8;
    const sh = this.state.sh * 0.8;
    const txt = 'Setiap GREENS kitchen dilengkapi dengan kebun dalam ruangan yang bernama GREENS pod. GREENS pod gunakan formula penanaman terkontrol untuk menghasikan kualitas panen terbaik dan teknologi web3 yang memakai NFT dan Blockchain untuk menjaga food safety.\n\nDengan GREENS pod, bahan makanan dapat ditanam dan dipanen di dalam dapur untuk langsung diproses menjadi hidangan yang mengandung zat gizi mikro sampai dengan 21x lipat lebih tinggi daripada biasanya.\n\nSistem dari menanam sampai menjadi hidangan didalam dapur ini adalah sistem pangan hyperlocal buatan GREENS.\n\nSistem hyperlocal ini hasilkan zat gizi mikro secara maksimal. Zat gizi mikro ini adalah beragam vitamin dan  mineral yang tubuh kita butuhkan untuk pertumbuhan, kesehatan otak, dan kekebalan tubuh, tapi sulit didapat diluar sistem pangan hyperlocal.';

    return (
      <Modal
        isVisible={this.state.modalIntroVisible}
        onBackdropPress={() => this.setState({ modalIntroVisible: false })}
      >
        <View style={{ alignItems: 'center', justifyContent: 'center' }}>
          <View style={{ backgroundColor: PRIMARY_COLOR, width: sw, height: sh, borderRadius: 16, overflow: 'hidden', padding: 10 }}>
            <View style={{ flex: 1, borderWidth: 1, borderStyle: 'dashed', borderRadius: 16, borderColor: 'white', paddingVertical: 30, }}>
              <ScrollView style={{ paddingHorizontal: 20 }}>
                <Text style={{ color: 'white', textAlign: 'center', fontSize: 14, fontWeight: 700 }}>KEUNGGULAN GREENS KITCHEN</Text>
                <Text> </Text>
                <Text style={{ color: 'white', textAlign: 'center', fontSize: 12, fontWeight: 500 }}>{txt}</Text>
                <Text> </Text>
                <Image source={{ uri: BG3_IMG }} style={{ height: 128, width: '100%', resizeMode: 'contain' }} />
                <Text> </Text>
                <View>
                  <Button type='outline' title='Tutup' onPress={() => this.setState({ modalIntroVisible: false })} />
                </View>
              </ScrollView>
            </View>
          </View>
        </View>
      </Modal>
    );
  }

  refundPolicy() {
    this.props.history.push('/refund-policy');
  }

  setItemAmount(item, delta) {
    const cartData = Object.assign({}, this.state.cartData);
    let amount = cartData.itemsAmount[item._id] ? cartData.itemsAmount[item._id] : 0;
    amount = amount + delta;
    if (amount < 0) amount = 0;
    cartData.itemsAmount[item._id] = amount;
    this.setState({ cartData });
  }

  renderModalDetail() {
    let item = this.state.selectedItem;
    if (!item) item = {};
    const sw = this.state.sw * 0.8;
    const sh = this.state.sh * 0.8;

    let uri = 'https://via.placeholder.com/160';
    if (item.linkImage && item.linkImage.length > 0) uri = item.linkImage;
    const title = item.title ? item.title.toUpperCase() : '';
    const desc = item.description ? item.description : '';
    const price = Lib.idrAmount(item.price);

    // const cartData = this.state.cartData;

    let selectedItemAmount = this.state.selectedItemAmount;

    const sTitle = { color: PRIMARY_COLOR, fontWeight: '700', fontSize: 14, marginBottom: 10 };
    const sDesc = { color: PRIMARY_COLOR, fontWeight: '400', fontSize: 12, marginBottom: 10 };

    return (
      <Modal
        isVisible={this.state.modalDetailVisible}
        onBackdropPress={() => this.setState({ modalDetailVisible: false })}
      >
        <View style={{ alignItems: 'center', justifyContent: 'center' }}>
          <View style={{ backgroundColor: 'white', width: sw, height: sh, borderRadius: 16, overflow: 'hidden' }}>
            <View style={{}}>
              <ImageBackground resizeMode='cover' source={{ uri: uri }} style={{ width: '100%', height: 200, borderTopLeftRadius: 16, borderTopRightRadius: 16, alignItems: 'flex-end', padding: 16 }} >
                <TouchableHighlight onPress={() => this.setState({ modalDetailVisible: false })}>
                  <Image source={{ uri: CLOSE }} style={{ height: 24, width: 24, resizeMode: 'stretch' }} />
                </TouchableHighlight>
              </ImageBackground>
            </View>
            <ScrollView style={{ flex: 1, padding: 28 }}>
              <Text style={sTitle}>{title}</Text>
              <Text style={sDesc}>{desc}</Text>
              <Text style={sTitle}>Menu ini terbuat dari microGREENS</Text>
              <Image source={{ uri: MG00 }} style={{ width: 147, height: 42, marginBottom: 10 }} />
              <Text style={sDesc}>{price}</Text>
              <View style={{ padding: 10, alignItems: 'center', justifyContent: 'center' }}>
                <SetNumber value={selectedItemAmount} onChange={delta => {
                  let newAmount = selectedItemAmount + delta;
                  if (newAmount < 0) newAmount = 0;
                  this.setState({ selectedItemAmount: newAmount });
                }} />
              </View>
              <View style={{ paddingHorizontal: 32 }}>
                <Button title='Masukan Keranjang' onPress={() => this.addToCart(item)} />
              </View>
            </ScrollView>
          </View>
        </View>
      </Modal>
    );
  }

  renderCell(item) {
    let uri = 'https://via.placeholder.com/160';
    if (item.linkImage && item.linkImage.length > 0) uri = item.linkImage;
    const title = item.title.toUpperCase();
    const price = Lib.idrAmount(item.price);

    return (
      <View style={{ flex: 1, padding: 10 }}>
        <TouchableHighlight onPress={() => this.clickMenu(item)}>
          <View style={{ backgroundColor: 'white', borderColor: 'gainsboro', borderWidth: 1, borderRadius: 15 }}>
            <Image source={{ uri: uri }} style={{ width: '100%', height: 160, borderTopLeftRadius: 15, borderTopRightRadius: 15, resizeMode: 'cover' }} />
            <View style={{ height: 90, padding: 10, justifyContent: 'flex-start' }}>
              <Text style={{ color: PRIMARY_COLOR, fontSize: 12, fontWeight: '700', marginBottom: 5 }}>{title}</Text>
              <Text style={{ color: PRIMARY_COLOR, fontSize: 12, fontWeight: '400' }}>{price}</Text>
            </View>
          </View>
        </TouchableHighlight >
      </View >
    );
  }

  renderMenu() {
    const items = this.state.saleItems;

    const products2d = [];
    let p2dRow = [];
    for (let i = 0; i < items.length; i++) {
      p2dRow.push(items[i]);
      if (p2dRow.length === 2) {
        products2d.push(p2dRow);
        p2dRow = [];
      }
    }

    if (p2dRow.length > 0) products2d.push(p2dRow);

    return (
      <View style={{ paddingVertical: 20, backgroundColor: 'white' }}>
        {
          products2d.map((item, index) => {
            const cell1 = this.renderCell(item[0]);
            let cell2 = <View style={{ flex: 1 }} />;
            if (item.length > 1) cell2 = this.renderCell(item[1]);
            return (
              <View key={'row' + index} style={{ flexDirection: 'row' }}>
                {cell1}
                {cell2}
              </View>
            );
          })
        }
      </View>
    );
  }

  render() {
    const isLogin = this.state.isLogin;

    const sw = this.state.sw;
    const slideHeight = (sw / 341) * 228;
    let loginText = null;

    // for header
    let showNumCoin = false;
    // let showHistory = false;

    if (!isLogin) {
      loginText = (
        <View style={{ paddingBottom: 0 }}>
          <Text style={{ textAlign: 'center', color: PRIMARY_COLOR }}>Sudah punya akun?&nbsp;
            <TouchableHighlight onPress={() => this.loginNow()}>
              <Text style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Login Sekarang</Text>
            </TouchableHighlight>
          </Text>
        </View>
      );
    } else {
      // loginText = (
      //   <View style={{ paddingBottom: 0 }}>
      //     <Text style={{ textAlign: 'center', color: PRIMARY_COLOR }}>
      //       <TouchableHighlight onPress={() => this.logout()}>
      //         <Text style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Log out</Text>
      //       </TouchableHighlight>
      //     </Text>
      //   </View>
      // );

      loginText = <Text> </Text>;

      showNumCoin = this.state.numCoin ? this.state.numCoin + '' : '0';
      // showHistory = true;
    }

    let btnOrder = <Button title='Pesan Sekarang' onPress={() => this.showCart()} />;
    let cartTotal = 0;
    const cartData = this.state.cartData;
    cartTotal = cartData.total;

    if (cartTotal > 0) {
      const title = 'Keranjang - ' + Lib.idrAmount(cartTotal);
      btnOrder = <Button type='dark' title={title} onPress={() => this.showCart()} />;
    }

    return (
      <View style={{ flex: 1 }}>
        {this.renderModalIntro()}
        {this.renderModalDetail()}
        <HeaderBar numCoin={showNumCoin}
          showMenu={true}
          isLogin={isLogin}
          // showHistory={showHistory} 
          // showCart={true}
          // onPressHistory={() => {
          //   this.orderHistory();
          // }}
          // onPressCart={() => {
          //   this.showCart();
          // }}
          history={this.props.history}
        />
        <ScrollView style={{ flex: 1 }}>
          <View style={{ height: 28 }} />
          <View style={{}}>
            <Image source={{ uri: SLIDE1 }} style={{ height: slideHeight, width: sw }} resizeMode='cover' />
          </View>
          {this.renderMenu()}
        </ScrollView>
        <View style={{ paddingVertical: 28, paddingHorizontal: 64 }}>
          {btnOrder}
          <View style={{ height: 20 }} />
          {loginText}

          <Text> </Text>
          {/* <TouchableHighlight onPress={() => this.refundPolicy()}>
            <Text style={{ color: PRIMARY_COLOR, fontWeight: 'bold', fontSize: 10, textAlign: 'center' }}>Syarat, ketentuan & kebijakan privasi</Text>
          </TouchableHighlight> */}

        </View>
      </View>
    );

  }
}

export default Home;
