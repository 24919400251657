import Lib from './Lib';


async function loadData() {
  let cartDataStr = await Lib.getStorageValue('CARTDATA');
  let cartData = {
    items: {},
    numItems: 0,
    total: 0
  };

  try {
    if (cartDataStr.length > 0) cartData = JSON.parse(cartDataStr);
  } catch (err) {
  }

  return cartData;
}

async function saveData(cartData) {
  await Lib.setStorageValue('CARTDATA', JSON.stringify(cartData));
}

async function resetData() {
  let cartData = {
    items: {},
    numItems: 0,
    total: 0
  };
  await Lib.setStorageValue('CARTDATA', JSON.stringify(cartData));
}


async function setItemAmount(item, newAmount) {
  const cartData = await loadData();
  if (!cartData.items) cartData.items = {};
  if (!cartData.items[item._id]) cartData.items[item._id] = Object.assign({}, item);
  cartData.items[item._id].amount = newAmount;

  let items = cartData.items;
  const newItems = {};
  for (let key of Object.keys(items)) {
    const item = items[key];
    if (item.amount > 0) newItems[item._id] = item;
  }

  cartData.items = newItems;
  items = cartData.items;

  let total = 0;
  let numItems = 0;

  for (let key of Object.keys(items)) {
    const item = items[key];
    let amount = item.amount ? item.amount : 0;
    let price = item.price;
    amount = Number(amount);
    price = Number(price);
    if (amount > 0 && price > 0) {
      total += (amount * price);
      numItems++;
    }
  }

  cartData.total = total;
  cartData.numItems = numItems;
  await saveData(cartData);
  return cartData;
}

async function getItems() {
  const cartData = await loadData();

  let items = cartData.items;
  const ret = [];
  for (let key of Object.keys(items)) {
    const item = items[key];
    ret.push({ _id: item._id, title: item.title, amount: item.amount });
  }

  return ret;
}


async function loadOrderData() {
  let str = await Lib.getStorageValue('ORDERDATA');

  // const name = this.state.name;
  // const phoneNum = this.state.phoneNum;
  // const address = this.state.address;
  // const notes = this.state.notes;

  let data = {
    name: '',
    phoneNum: '',
    address: '',
    notes: ''
  };

  try {
    if (str.length > 0) data = JSON.parse(str);
  } catch (err) {
  }

  return data;
}

async function saveOrderData(data) {
  await Lib.setStorageValue('ORDERDATA', JSON.stringify(data));
}

async function resetOrderData() {
  let data = {
    name: '',
    phoneNum: '',
    address: '',
    notes: ''
  };
  await Lib.setStorageValue('ORDERDATA', JSON.stringify(data));
}

export default {
  setItemAmount,
  getItems,
  loadData,
  saveData,
  resetData,
  loadOrderData,
  saveOrderData,
  resetOrderData
};

