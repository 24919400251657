import React from 'react';
import { TouchableHighlight, Text } from 'react-native';

const { PRIMARY_COLOR, DARK_COLOR } = require('./Data');

class Button extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  async componentDidMount() {
  }

  componentWillMount() {
  }

  click() {
    if (this.props.disabled) return;
    this.props.onPress();
  }

  render() {
    let opacity = 1.0;
    const darkColor = DARK_COLOR;
    let btnCol = PRIMARY_COLOR;

    if (this.props.disabled) opacity = 0.5;
    if (this.props.type === 'outline') {
      return (
        <TouchableHighlight style={{
          opacity: opacity,
          flex: 1,
          backgroundColor: 'white', borderColor: PRIMARY_COLOR, borderWidth: 1, borderRadius: 16, overflow: 'hidden', paddingVertical: 8,
          paddingHorizontal: 16
        }} onPress={() => this.click()}>
          <Text style={{ color: PRIMARY_COLOR, fontWeight: 700, textAlign: 'center' }}>{this.props.title}</Text>
        </TouchableHighlight>
      );
    } else if (this.props.type === 'dark') {
      btnCol = darkColor;
    }

    return (
      <TouchableHighlight style={{
        opacity: opacity,
        flex: 1,
        backgroundColor: btnCol, borderColor: btnCol, borderWidth: 1, borderRadius: 16, overflow: 'hidden', paddingVertical: 8,
        paddingHorizontal: 16
      }} onPress={() => this.click()}>
        <Text style={{ color: 'white', fontWeight: 700, textAlign: 'center' }}>{this.props.title}</Text>
      </TouchableHighlight>
    );

  }
}

export default Button;
